/* @ Reboot */

body {
	overflow: hidden;
}

.layout-container {
	margin: 0 !important;
	max-width: 100% !important;
}

*,*::after,*::before {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 700;
}

h1, h2, h3, h4, h5, h6, p {
	margin-bottom: 0;
}

p, .p1, .p2 {
	font-weight: 400;
}

h1 {
	font-size: 28px;
	line-height: 42px;
	letter-spacing: -0.2px;
}

h2 {
	font-size: 23px;
	line-height: 34.5px;
	letter-spacing: -0.2px;
}

.p1 {
	font-size: 16px;
	line-height: 22.4px;
	letter-spacing: -0.2px;
}

.p2 {
	font-size: 13px;
	line-height: 18.2px;
	letter-spacing: -0.2px;
}

.t1 {
	font-size: 40px;
	font-weight: 700;
	line-height: 60px;
	letter-spacing: -0.2px;
}

a,
a:hover,
a:active {
	color: inherit;
	text-decoration: none;
}

.landing-container {
	font-family: 'LineSeedSansTH';
	max-width: 100vw;
	min-height: 100vh;
	height: auto;
	color: #FFF;
	background-color: #262626;
	overflow: hidden;
}

/* @ Loading */

.landing-loading {
	position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1500;
    color: #FFF;
	background-color: #17191B;

	&.unseen {
		display: none;
	}

	.loading-logo {
		width: 152px;
		height: 152px;
	}
}

.fade-logo {
	display: flex;
	justify-content: center;

	.logo-outer {
		top: -183px;
		left: calc(50% - 56px);
		position: absolute;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 112px;
		height: 112px;
		border-radius: 56px;
		border: 1.4px solid #25272A;
		background-color: #17191B;
	}
		.logo-inner {
			width: 96.05px;
			height: 96.05px;
			border-radius: 56px;
			border: 1.4px solid #25272A;
			background: #17191B;
			box-shadow: -4.657px -4.657px 5.821px 0px rgba(48, 51, 57, 0.23), 5.821px 5.821px 6.985px 0px rgba(0, 0, 0, 0.60), -2.328px -2.328px 3.493px 0px rgba(255, 255, 255, 0.10), 2.328px 2.328px 4.657px 0px rgba(0, 0, 0, 0.90);
		}

		.king-logo {
			position: absolute;
			top: 10px;
    		left: 13px;
		}
}

.fade1 {
	position: relative;
	overflow: hidden;
	max-width: 100vw;
    min-height: 100vh;
	background: #17191B;
	display: flex;
	align-items: center;
	justify-content: center;

	.bg {
		position: absolute;
		top: -47px;
		height: auto;
		// left: -132.558%;
	}

	.castle {
		position: absolute;
		top: -47px;
		// left: -131.395%;
	}

	.pavement {
		position: absolute;
		top: -47px;
		// left: -132.558%;
	}

	.archway {
		position: absolute;
		height: auto;
		// left: -132.558%;
	}

	.bg2 {
		position: absolute;
		align-self: flex-start;
		top: -47px;
		height: auto;
		// left: -132.558%;
	}

	.longshot {
		position: absolute;
		align-self: flex-start;
		top: -47px;
		height: auto;
		// left: -132.558%;
	}

	.front-cliff {
		position: absolute;
		align-self: flex-start;
		height: auto;
		top: -47px;
	}

	.above-cliff {
		position: absolute;
		align-self: flex-start;
		top: -47px;
	}

	.fade-clouds img {
		z-index: 1;
	}

	.cloud1-1 {
		position: absolute;
		width: 567.424px;
		height: 303.056px;
		top: 365px;
		left: -118px;
	}

	.cloud1-2 {
		position: absolute;
		width: 275.883px;
		height: 145.541px;
		top: 419.35px;
		left: 174.5px;
	}

	.cloud2-1 {
		position: absolute;
		top: 17px;
		left: 139.45px;
		mix-blend-mode: screen;
	}

	.cloud2-2 {
		position: absolute;
		top: 33.5px;
		left: 60px;
		mix-blend-mode: screen;
	}

	.cloud3-1 {
		position: absolute;
		top: 153px;
		left: -186px;
		mix-blend-mode: screen;
	}

	.cloud3-2 {
		position: absolute;
		top: 200.5px;
		left: -178px;
		mix-blend-mode: screen;
	}

	.cloud4-1 {
		position: absolute;
		top: -9px;
		left: -102px;
		mix-blend-mode: screen;
	}

	.cloud4-2 {
		position: absolute;
		top: 42.75px;
		left: -124px;
	}

	.cloud5-1 {
		position: absolute;
		top: 257px;
		left: 213px;
		mix-blend-mode: screen;
	}

	.cloud5-2 {
		position: absolute;
		top: 300px;
		left: 200px;
		mix-blend-mode: screen;
	}

	.cloud5-3 {
		position: absolute;
		top: 326px;
		left: 189px;
	}

	.cloud6-1 {
		position: absolute;
		width: 618.63px;
		height: 330.48px;
		top: 470px;
		left: -56.89px;
		mix-blend-mode: screen;
	}

	.cloud6-2 {
		position: absolute;
		width: 628.32px;
		height: 335.58px;
		top: 542.42px;
		left: -88px;
	}

	.fade-welcome {
		display: flex;
    	justify-content: center;
	}

	.op-black {
		position: absolute;
		width: 100%;
		height: 253px;
		left: 0;
		bottom: -179px;
	}

	.welcome-text {
		position: absolute;
		left: calc(50% - 156.5px);
		bottom: -88px;
		opacity: 0;
	}

}

.fade2 {
	position: fixed;
	inset: 0;
	display: none;
	height: 100vh;
	width: 100vw;
	align-items: center;
	justify-content: center;
	transform-origin: 50% 20%;

	.bg-king {
		position: absolute;
		height: auto;
		bottom: -52px;
		opacity: 0;
	}

	.king-welcome-text {
		position: absolute;
		height: auto;
		bottom: 168px;
		height: auto;
		object-fit: contain;
		object-position: center;
	}

	.lsm99king-text {
		position: absolute;
		height: auto;
		bottom: 37.5px;
	}

	.the-king {
		position: absolute;
		width: auto;
		bottom: 81px;
		scale: 0;
		opacity: 0;
	}

	.btn-login {
		width: 183px;
		position: absolute;
		right: -145px;
		bottom: 80px;
		opacity: 0;
	}

	.btn-register {
		width: 183px;
		position: absolute;
		left: -145px;
		bottom: 80px;
		opacity: 0;
	}

	.scroll-mouse {
		position: absolute;
		bottom: -56px;
	}

	.scroll-text {
		position: absolute;
		bottom: -160px;
		font-weight: 700;
		color: #D9B968;
	}

}

.fade3 {
	display: none;
	align-items: center;
	justify-content: center;
	top: 0; left: 0;
	height: 100vh;
	width: 100vw;
	position: fixed;
	z-index: 1;
	background-color: #17191B;

	.mask {
		position: absolute;
		will-change: transform;
		z-index: 10;
		scale: 19;
		translate: -698px 96px;
		top: 296px;
	}

	.navbar {
		display: flex;
		position: absolute;
		align-content: center;
		justify-content: center;
		top: -183px;
		gap: 16px;
		z-index: 10;

		.navbar-item {
			width: 183px;
		}

		.navbar-logo-outer {
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 80px;
			height: 80px;
			border-radius: 40px;
			border: 1px solid #25272A;
			background-color: #17191B;
			box-shadow: -8px -8px 10px 0px rgba(48, 51, 57, 0.23), 10px 10px 12px 0px rgba(0, 0, 0, 0.60), -4px -4px 6px 0px rgba(255, 255, 255, 0.10), 4px 4px 8px 0px rgba(0, 0, 0, 0.90);
		}

		.navbar-logo {
			margin-bottom: 2px;
		}
	}

	.title-1 {
		position: absolute;
		top: 329px;
		color: #7A8087;
		text-align: center;
		opacity: 0;

		& > span {
			color: #F4F5F6;
		}
	}

	.subtitle-1 {
		position: absolute;
		top: 545px;
		color: #7A8087;
		text-align: center;
		max-width: 382px;
		opacity: 0;

		& > b {
			color: #F4F5F6;
		}
	}

	.smartphone {
		position: absolute;
		width: 437px;
		height: 276px;
		left: 28px;
		top: 668px;
		rotate: 35.5deg;
		opacity: 0;

		.phone-wrap {
			position: absolute;
			left: calc(50% - 145px / 2);
			width: 145px;
			height: 100%;
		}

		img {
			height: 100%;
		}

		.phone-1 {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		.phone-2,
		.phone-3,
		.phone-4,
		.phone-5 {
			width: 123px;
			height: 196px;
			position: absolute;
			top: calc(50% - 196px / 2);
			left: calc(50% - 123px / 2);
		}
	}

	.balloons {
		& > img {
			position: absolute;
		}

		.balloon-1 {
			width: 130.5px;
			height: auto;
			top: 1350px;
			left: 193px;
		}

		.balloon-2 {
			width: 114px;
			height: auto;
			top: 2004px;
			left: 416px;
		}

		.balloon-3 {
			width: 274.5px;
			height: auto;
			top: 875px;
			left: 187px;
		}

		.balloon-4 {
			width: auto;
			height: 210px;
			top: 1260px;
			left: 50px;
		}

		.balloon-5 {
			width: 163px;
			height: auto;
			top: 875px;
			left: 46px;
		}

		.balloon-6 {
			width: 65px;
			height: auto;
			top: 1551px;
			left: 177px;
		}

		.balloon-7 {
			width: 293px;
			height: auto;
			top: 1445px;
			left: 231px;
		}

		.balloon-8 {
			width: 276px;
			height: auto;
			top: 1049px;
			right: 199px;
		}

		.balloon-9 {
			width: 225px;
			height: auto;
			top: 1679px;
			left: 41px;
		}

		.balloon-10 {
			width: 250.5px;
			height: auto;
			top: 1281px;
			left: -59.5px;
			rotate: -19.46deg;
		}

		.balloon-11 {
			width: 240px;
			height: auto;
			top: 1099px;
			right: -52px;
		}
	}

	.balloon-texts {
		position: absolute;
		width: 100%;
		color: #7A8087;
		background-color: #17191B;
		top: calc(100% + 128px);

		.balloon-text-1 {
			position: relative;
			font-weight: 700;
			top: 0;
			left: 24px;
			margin-bottom: 4px;
		}

		.balloon-inner {
			display: flex;
			position: relative;
			left: 24px;
		}

		.i-2 {
			top: -8px;
		}

		.balloon-title {
			position: relative;
			font-size: 40px;
			font-weight: 700;
			line-height: 60px;
			letter-spacing: -0.2px;
		}

		.t-1 {
			top: 40px;
		}

		.t-2 {
			top: 80px;
			color: #F4F5F6;
			margin-left: 20px;
		}

		.t-4 {
			color: #F4F5F6;
			margin-left: 16px;
		}

		.balloon-text-2 {
			position: relative;
			left: 24px;
			top: 48px;

			& > span {
				color: #F4F5F6;
			}
		}
	}

	.category-show {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 24px 16px;
		margin-top: 32px;

		.cate-1 {
			top: 16px;
		}

		.cate-2 {
			top: 88px;
		}

		.cate-3 {
			top: 80px;
		}

		.cate-4 {
			top: 56px;
		}

		.cate-5 {
			top: 96px;
		}
	}

	.pre-show {
		width: 425px;
		height: 498px;
		position: absolute;
		left: calc(50% - 425px / 2);

		img {
			position: absolute;
		}

		.pre-casino > img,
		.pre-slot > img,
		.pre-lotto > img,
		.pre-other > img {
			height: auto;
			opacity: 0;
		}

		// Sport
		.sp-1 {
			width: 188px;
			height: auto;
			right: 5px;
			bottom: 5px;
		}

		.sp-2 {
			width: 159px;
			height: auto;
			left: -5px;
			top: -13px;
		}

		.sp-3 {
			width: 264px;
			height: auto;
			left: 67px;
			top: 0px;
		}

		.sp-4 {
			width: 189.5px;
			height: auto;
			left: 49px;
			top: 17px;
		}

		.sp-5 {
			width: 312px;
			height: auto;
			right: 8.5px;
			bottom: 126px;
		}

		.sp-6 {
			width: 170px;
			height: auto;
			right: 9.5px;
			top: 7px;
		}

		.sp-7 {
			width: 105px;
			height: auto;
			left: 5px;
			top: 79px;
		}

		.sp-8 {
			width: 289.5px;
			height: auto;
			left: 0;
			bottom: 4.75px;
		}

		.sp-9 {
			width: 180.5px;
			height: auto;
			left: 49px;
			bottom: 9px;
		}
	}


}

.provider-section {
	position: absolute;
	width: 100%;
	color: #7A8087;
	background-color: #17191B;
	padding-top: 37px;
	top: calc(100% + 62px);

	.provider-text-1 {
		position: relative;
		font-weight: 700;
		top: 0;
		left: 33px;
		margin-bottom: 4px;
	}

	.provider-inner {
		display: flex;
		position: relative;
		left: 33px;
	}

	.provider-title {
		position: relative;
		font-size: 40px;
		font-weight: 700;
		line-height: 60px;
		letter-spacing: -0.2px;
	}

	.provider-list {
		// margin-top: 20px;
		top: -8px;
		width: 398px;
		height: 597px;
		position: relative;
		left: calc(50% - 398px / 2);

		& > img {
			position: absolute;
		}

		.pv { opacity: 0; }

		.pv-4 { top: 20px; right: 0 }
		.pv-3 { top: 27.5px; right: 80px }
		.pv-2 { top: 23.8px; left: 130px }
		.pv-1 { top: 31.5px; left: 2px }

		.pv-5 { top: 56.5px; left: 5px }
		.pv-6 { top: 86.5px; left: 76px }
		.pv-7 { top: 82.5px; right: 103.5px }
		.pv-8 { top: 86.5px; right: 6.5px }

		.pv-9 { top: 117.5px; left: 2px }
		.pv-10 { top: 124px; left: 65px }
		.pv-11 { top: 124px; left: 128px }
		.pv-12 { top: 105.8px; right: 57px }
		.pv-13 { top: 124px; right: 4.5px }

		.pv-14 { top: 179px; left: 2px }
		.pv-15 { top: 191px; left: 70.5px }
		.pv-16 { top: 159.8px; left: 149px }
		.pv-17 { top: 175px; right: 136.5px }
		.pv-18 { top: 175px; right: 5px }
		.pv-19 { top: 209px; right: 130.5px }
		.pv-20 { top: 208px; right: 50px }

		.pv-21 { top: 222.5px; left: 2px }
		.pv-22 { top: 214.5px; left: 86px }
		.pv-23 { top: 223.5px; right: 37.5px }
		.pv-i { top: 219.5px; right: 6px }

		.pv-24 { top: 283px; left: 10px }
		.pv-25 { top: 287px; left: 108px }
		.pv-26 { top: 287px; left: 149px }
		.pv-27 { top: 274.5px; right: 59px }
		.pv-28 { top: 279.5px; right: 0 }

		.pv-29 { top: 355.5px; left: 16px }
		.pv-30 { top: 339px; right: 63.5px }
		.pv-ii { top: 342px; right: 6px }

		.pv-31 { top: 396px; left: 13px }
		.pv-iii { top: 396px; left: 149px }
		.pv-j { top: 393.5px; right: 81px }
		.pv-jj { top: 407px; right: 9px }

		.pv-32 { top: 415.5px; left: 0 }
		.pv-33 { top: 444px; left: 105px }
		.pv-34 { top: 418px; left: 160px }
		.pv-jjj { top: 446px; right: 79px }
		.pv-k { top: 459.5px; right: 13.5px }

		.pv-35 { top: 500px; left: 13px }
		.pv-36 { top: 492.5px; left: 74px }
		.pv-37 { top: 490.5px; right: 74px }
		.pv-kk { top: 486.5px; right: 5px }

		.pv-38 { top: 559px; left: 9px }
		.pv-39 { top: 571.5px; right: 135.5px }
		.pv-kkk { top: 567.5px; right: 5px }
	}

	.i-2 {
		top: -8px;
	}

	.t-1 {
		color: #F4F5F6;
		top: 120px;
	}

	.t-2 {
		color: #F4F5F6;
		margin-left: 3px;
	}

	.t-3 {
		top: 37px;
	}

	.t-4 {
		color: #F4F5F6;
		margin-left: 4px;
	}
}

.friend-section {
	color: #969DA6;
	position: absolute;
    width: 100%;
    height: 100vh;
	left: 0;
	right: 0;
	top: calc(100% + 43px);

	.friend-ipad {
		position: absolute;
		top: 116px;
		left: 0;
		width: 100%;
		height: calc(100vh - 116px);
		overflow: hidden;
	}

	.friend-wrapper {
		overflow: hidden;
		height: calc(100% - 8px);
		width: 100%;
		margin-top: 8px;
		position: absolute;
	}

	.ipad-mock {
		position: absolute;
		will-change: transform;
		width: 1198px;
		height: auto;
		top: -26px;
		left: calc(50% - 1198px / 2);
	}

	.friend-bg {
		position: absolute;
		will-change: transform;
		width: 7877px;
		height: auto;
		top: -854px;
		right: -2486px;
		opacity: .4;
	}

	.friend-text-1 {
		position: absolute;
		top: 216px;
		left: 24px;
		opacity: 0;
	}

	.friend-title-1 {
		position: absolute;
		top: 271px;
		left: 24px;
		opacity: 0;
	}

	.friend-title-2 {
		position: absolute;
		top: 298px;
		left: 24px;
		opacity: 0;
	}

	.friend-text-2 {
		position: absolute;
		top: 413px;
		left: 24px;
		opacity: 0;
	}

	.friend-text-3 {
		position: absolute;
		top: 424px;
		left: 24px;
		opacity: 0;
	}

	.friend-text-4 {
		position: absolute;
		top: 435px;
		left: 24px;
		opacity: 0;
	}

	.friend-title-3 {
		position: absolute;
		top: 541px;
		left: 24px;
		opacity: 0;
	}

	.friend-title-4 {
		position: absolute;
		top: 583px;
		left: 24px;
		opacity: 0;
	}

	.friend-title-5 {
		position: absolute;
		bottom: -100px;
		left: 24px;
		opacity: 0;
	}

	.friend-text-5 {
		position: absolute;
		top: 673px;
		left: 24px;
		opacity: 0;
	}

	.friend-text-6 {
		position: absolute;
		top: 702px;
		left: 24px;
		opacity: 0;
	}

	.friend-text-7 {
		position: absolute;
		top: 732px;
		left: 24px;
		opacity: 0;
	}

	span {
		color: #F4F5F6;
	}

}

/* @ Landing (Desktop) */
// H: 472 / 1024 * 100 = 46.09375 = 32.2265625 = 24.31640625 = 19.53125 = -48.291015625 = -52.734375 = -50.390625 = -61.9140625 = 68.26171875 = 54.39453125 = 73.4375 = 45.8984375 = 32.03125 = 23.53515625 = 18.75 = -41.748046875 = -46.19140625 = -33.203125 = -44.7265625 = 67.1875 =
// W: 905 / 1440 * 100 = 62.84722222222222 = 102.95138888888889 = 84.23611111111111 = 32.15277777777778 = -67.88194444444444 = 80.69444444444444 = -15.17361111111111 = -63.50694444444444 = 102.88194444444446 = -88.4375 = -84.20138888888889 = -37.15277777777778 = -59.09722222222222 = -54.861111111111114 = -24.51388888888889 = -24.02777777777778 = -59.44444444444444 = 72.56944444444444 = 0.8333333333333334 = -43.125 = 73.61111111111111 = 70.69444444444444 = 54.93055555555556 =

.landing-desktop {
	position: relative;
    width: 100vw;
    height: 100vh;

	.frame-test {
		position: absolute;
		background-color: red;
		width: 100px;
		height: 5px;
		z-index: 10;
		top: 24px;
		left: calc(50% - 100px / 2);
	}

	.frame-logo {
		display: flex;
		justify-content: center;

		.logo-outer {
			top: -382px;
			left: calc(50% - 342px / 2);
			position: absolute;
			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 342px;
			height: 342px;
			border-radius: 171px;
			border: 4.27px solid #25272A;
			background-color: #17191B;
		}

		.logo-inner {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 293px;
			height: 293px;
			border-radius: 171px;
			border: 4.27px solid #25272A;
			background: #17191B;
			box-shadow:
				7.11px 7.11px 14.22px 0px #000000E5,
				-7.11px -7.11px 10.67px 0px #FFFFFF1A,
				17.78px 17.78px 21.33px 0px #00000099,
				-14.22px -14.22px 17.78px 0px #3033393B;
		}

		.king-logo {
			position: relative;
			top: -10px;
		}
	}

	.frame-1 {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		inset: 0;

		.frame-bg {
			position: absolute;
			top: 50%;
			width: 104.02%;
			transform: translateY(-50%);
			mix-blend-mode: difference;
			opacity: 0.38;
		}

		.frame-castle {
			position: absolute;
			top: 50%;
			width: 104.02%;
			transform: translateY(-50%);
		}

		.frame-op {
			position: absolute;
			width: 100%;
			height: 802px;
			bottom: -345px;
			left: 0;
			right: 0;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 50.5%, rgba(0, 0, 0, 0) 100%);
		}

		.pole-start {
			position: absolute;
			height: 100%;
			top: 50%;
			left: 0;
			transform: translateY(-50%) rotateY(.5turn);
		}

		.pole-end {
			height: 100%;
			top: 50%;
			right: 0;
			position: absolute;
			transform: translateY(-50%);
		}

		.welcome-text {
			position: absolute;
			z-index: 1;
			width: 301px;
			bottom: -15px;
		}

		.frame-clouds > img {
			position: absolute;
			z-index: 1;
		}

		.cloud1-1 {
			position: absolute;
			top: 41.40%;
			right: -1.66%;
			mix-blend-mode: screen;
		}

		.cloud2-1 {
			top: 53.32%;
			left: 30.55%;
			mix-blend-mode: screen;
		}

		.cloud2-2 {
			top: 67.18%;
			left: 33.40%;
			transform: rotateY(.5turn);
		}

		.cloud3-1 {
			top: -18.16%;
			left: -42.77%;
			transform: rotateY(.5turn);
		}

		.cloud3-2 {
			top: -6.64%;
			left: 1.18%;
		}

		.cloud4-1 {
			top: -6.64%;
			right: -24.3%;
			mix-blend-mode: screen;
		}

		.cloud4-2 {
			top: -2.19%;
			right: -11.18%;
			mix-blend-mode: screen;
		}

		.cloud5-1 {
			top: 31.25%;
			right: 27.01%;
			mix-blend-mode: screen;
		}

		.cloud5-2 {
			top: 36.03%;
			right: 26.52%;
			mix-blend-mode: screen;
			transform: rotateY(.5turn);
		}

		.cloud6-1 {
			top: 31.25%;
			left: -13.61%;
			mix-blend-mode: screen;
			transform: rotateY(.5turn);
		}

		.cloud6-2 {
			top: 45.11%;
			left: -17.84%;
		}
	}

	.frame-2 {
		display: none;
		justify-content: center;
		top: 0; left: 0;
		height: 100vh;
		width: 100vw;

		.bg-king {
			width: 3617px;
			position: absolute;
			top: -63.47%;
			left: calc(50% - 3617px / 2);
			opacity: 0;
			transform: translateX(-50%);
			scale: 5.52;
		}

		.king-title-top {
			position: absolute;
			bottom: -22.65%;
			opacity: 0;
		}

		.king-title-bottom {
			position: absolute;
			bottom: -15.08%;
			opacity: 0;
		}

		.king-welcome {
			position: absolute;
			bottom: 31.34%;
			opacity: 0;
		}

		.login-text {
			position: absolute;
			top: 364px;
			right: 221px;
			width: 512px;
			height: auto;
			opacity: 0;
		}

		.register-text {
			position: absolute;
			top: 364px;
			left: 100.5px;
			width: 608px;
			height: auto;
			opacity: 0;
		}

		.the-king {
			position: absolute;
			bottom: 19.58%;
			opacity: 0;
		}

		.btn-login {
			width: 273px;
			position: absolute;
			bottom: 16.01%;
			right: -2.98%;
			opacity: 0;
		}

		.btn-register {
			width: 273px;
			position: absolute;
			bottom: 16.01%;
			left: 3.75%;
			opacity: 0;
		}

		.scroll-mouse {
			width: 56px;
			position: absolute;
			bottom: -80px;
			opacity: 0;
		}

		.scroll-text {
			position: absolute;
			color: #D9B968;
			bottom: -102px;
			opacity: 0;
		}
	}

	.frame-3 {
		display: none;
		align-items: center;
		justify-content: center;
		top: 0; left: 0;
		height: 100vh;
		width: 100vw;
		position: fixed;
		color: #6C7074;
		background-color: rgba(23, 25, 27, 1);

		.mask {
			position: absolute;
			top: 50%;
			translate: -2148px -50%;
			scale: 42;
		}

		.landing-navbar {
			top: -82px;
			right: 50%;
			translate: 50% 0;
			opacity: 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: absolute;
			width: 79.16%;
			height: 50px;
			border-radius: 16px;
			border: 1px solid #25272A;
			background-color: #17191B;
			box-shadow: -8px -8px 10px 0px rgba(48, 51, 57, 0.23), 10px 10px 12px 0px rgba(0, 0, 0, 0.60), -4px -4px 6px 0px rgba(255, 255, 255, 0.10), 4px 4px 8px 0px rgba(0, 0, 0, .9);

			.navbar-logo {
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				width: 124px;
				height: 124px;
				border-radius: 50%;
				border: 4.275px solid #25272A;
				background: #17191B;

				&::before {
					content: ' ';
					width: 106.5px;
					height: 106.5px;
					position: absolute;
					border-radius: 50%;
					border: 4.275px solid #25272A;
					background-color: #17191B;
					box-shadow: -14.22px -14.22px 17.775px 0px rgba(48, 51, 57, 0.23), 17.775px 17.775px 21.331px 0px rgba(0, 0, 0, 0.60), -7.11px -7.11px 10.665px 0px rgba(255, 255, 255, 0.10), 7.11px 7.11px 14.22px 0px rgba(0, 0, 0, 0.90);
				}
			}

			.logo {
				position: relative;
				top: -3.5px;
				width: 89.58px;
				height: 89.58px;
			}

			.navbar-start {
				flex: 0 0 368px;
			}

			.navbar-end {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 16px;

				& > button {
					width: 176px;
				}
			}
		}

		.w-header {
			position: absolute;
			top: -24.12%;
			opacity: 0;
			font-size: 75px;
			font-weight: 900;
			line-height: normal;
			letter-spacing: 0.751px;
			background: linear-gradient(90deg, #BA8A46 0%, #F5E386 50%, #BA8A46 100%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		.w-title {
			position: absolute;
			top: 66.5%;
			opacity: 0;
		}

		.w-subtitle {
			position: absolute;
			width: 744px;
			top: 77.92%;
			opacity: 0;
			text-align: center;
		}

		.smartphone {
			position: absolute;
			width: 958px;
			height: 605px;
			left: 18.75%;
			top: 54.73%;
			rotate: -47.74deg;
			opacity: 0;

			.phone-wrapper {
				position: absolute;
				left: calc(50% - 317.5px / 2);
				width: 317.5px;
				height: 100%;
			}

			img {
				height: 100%;
			}

			.phone-1 {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			.phone-2,
			.phone-3,
			.phone-4,
			.phone-5 {
				width: 279px;
				height: 442px;
				position: absolute;
				top: calc(50% - 442px / 2);
				left: calc(50% - 279px / 2);
				filter: drop-shadow(-8.067px 12.1px 8.067px rgba(0, 0, 0, 0.40));
			}
		}

		.balloon-wrapper {
			position: absolute;
			width: 89.51%;
			height: 259.57%;
			// background-color: orangered;
			// top: -800px;
			// scale: 0.3;
			top: 100%;

			& > img {
				position: absolute;
			}

			.balloon-1 {
				width: 20.71%;
				height: 12.64%;
				top: 36.49%;
				left: 46.54%;
			}

			.balloon-2 {
				width: 18.03%;
				height: 13.11%;
				bottom: 0;
				right: 0;
			}

			.balloon-3 {
				width: 43.56%;
				height: 24.17%;
				top: 0;
				right: 10.86%;
			}

			.balloon-4 {
				width: 29.13%;
				height: 16.15%;
				top: 29.55%;
				left: 23.81%;
			}

			.balloon-5 {
				width: 25.83%;
				top: 0;
				left: 23.19%;
			}

			.balloon-6 {
				width: 10.31%;
				height: 11.79%;
				bottom: 36.15%;
				left: 43.98%;
			}

			.balloon-7 {
				width: 46.5%;
				height: 49.56%;
				bottom: 6.54%;
				right: 0.93%;
			}

			.balloon-8 {
				width: 43.83%;
				height: 30.15%;
				top: 13.39%;
				left: 8.72%;
			}

			.balloon-9 {
				width: 35.68%;
				height: 19.77%;
				bottom: 18.32%;
				left: 22.38%;
			}

			.balloon-10 {
				width: auto;
				height: 31.41%;
    			top: 31.24%;
				left: 0;
			}

			.balloon-11 {
				width: 38.13%;
				height: 23.26%;
				top: 17.23%;
				right: 7.56%;
			}

		}

		.balloon-texts {
			.balloon-header {
				position: absolute;
				top: 318.84%;
				left: 13.81%;
				font-size: 17px;
				font-weight: 700;
				line-height: 24px;
				letter-spacing: -0.25px;
				opacity: 0;
			}

			.balloon-title {
				position: absolute;
				font-size: 53.252px;
				font-weight: 700;
				line-height: 79.878px;
				letter-spacing: -0.266px;
				opacity: 0;
			}

			.bt-1 {
				top: 321.67%;
				left: 13.81%;
			}

			.bt-2 {
				top: 321.67%;
				left: 29.06%;
			}

			.bt-3 {
				top: 328.46%;
				left: 13.81%;
			}

			.bt-4 {
				top: 328.46%;
				left: 29.06%;
			}

			.bt-5 {
				position: absolute;
				top: 337.98%;
				left: 13.81%;
				opacity: 0;
			}
		}

		.category-show {
			display: flex;
			align-items: flex-end;
			justify-content: center;
			position: absolute;
			gap: 25px;
			height: 101px;
			width: 67.56%;
			top: 45.31%;
			left: 15.625%;
			padding-bottom: 1.5px;
			opacity: 0;

			.w-category {
				min-width: 169.5px;
    			height: 58.5px;
			}
		}

		.pre-show {

			img {
				position: absolute;
			}

			.pre-casino > img,
			.pre-slot > img,
			.pre-lotto > img,
			.pre-other > img {
				height: auto;
				opacity: 0;
			}

			.cs-2 {
				rotate: -174.64deg;
			}

			.cs-3 {
				rotate: -30.33deg;
			}

			.cs-4 {
				rotate: 127.25deg;
			}

			.cs-5 {
				rotate: 127.25deg;
			}

			.cs-6 {
				rotate: 113.12deg;
			}

			.cs-7 {
				rotate: 113.12deg;
			}

			.cs-9 {
				rotate: 12.4deg;
			}

			.sl-1 {
				rotate: -10.84deg;
			}

			.sl-2 {
				rotate: 170.07deg;
			}

			.sl-3 {
				rotate: 16.92deg;
			}

			.sl-5 {
				rotate: 63.34deg;
			}

			.sl-6 {
				rotate: 9.11deg;
			}

			.sl-8 {
				rotate: -21.34deg;
			}

			.lt-5 {
				rotate: -9.29deg;
			}

			.ot-2,
			.ot-3,
			.ot-4 {
				rotate: 4.82deg;
			}
		}

		.provider-wrapper {
			color: #7A8087;

			.provider-header {
				position: absolute;
				top: 128.41%;
				left: 10.27%;
				font-size: 25.146px;
				font-weight: 700;
				line-height: 35.205px;
				letter-spacing: -0.387px;
			}

			.provider-title {
				opacity: 0;
				position: absolute;
				font-size: 77.373px;
				font-weight: 700;
				line-height: 116.06px;
				letter-spacing: -0.387px;
			}

			.pvt-1,
			.pvt-2,
			.pvt-4,
			.pvt-5 {
				color: #F4F5F6;
			}

			.pvt-1 {
				top: 132.56%;
				left: 10.27%;
			}

			.pvt-2 {
				top: 132.56%;
				left: 29.2%;
			}

			.pvt-3 {
				top: 142.38%;
				left: 10.27%;
			}

			.pvt-4 {
				top: 142.38%;
				left: 35.27%;
			}

			.pvt-5 {
				// top: 153.5%;
				// left: 35.27%;
				top: 67.57%;
				left: 32.48%;
			}

			.provider-list {
				width: 610px;
				height: 700px;
				position: absolute;
				top: 104.49%;
				right: 5%;
				overflow: hidden;

				& > img {
					position: absolute;
				}

				.pv { opacity: 0; }
				.pv-1 { top: -66.5px; left: 3px; }
				.pv-2 { top: -56.5px; left: 99.5px; }
				.pv-3 { top: -1.5px; left: 228px; }
				.pv-4 { top: 22px; right: 229.5px; }
				.pv-5 { top: 22px; right: 8px; }

				.pv-6 { top: 28px; left: 7.5px; }
				.pv-7 { top: 47px; left: 108.5px; }
				.pv-8 { top: 73.5px; right: 200.5px; }
				.pv-9 { top: 72px; right: 76.5px; }
				.pv-10 { top: 90px; right: 9px; }

				.pv-11 { top: 94px; left: 3px; }
				.pv-12 { top: 82px; left: 131.5px; }
				.pv-13 { top: 95.5px; right: 58px; }

				.pv-14 { top: 187.5px; left: 15px; }
				.pv-15 { top: 193px; left: 165.5px; }
				.pv-16 { top: 193px; left: 228px; }
				.pv-17 { top: 147px; right: 90.5px; }
				.pv-18 { top: 181.5px; right: 0; }

				.pv-19 { top: 298px; left: 24.5px; }
				.pv-20 { top: 273px; right: 97.5px; }
				.pv-21 { top: 277.5px; right: 10px; }

				.pv-22 { bottom: 294px; left: 20px; }
				.pv-23 { bottom: 287px; left: 228px; }
				.pv-24 { bottom: 256.5px; right: 125px; }
				.pv-25 { bottom: 272px; right: 14.5px; }

				.pv-26 { bottom: 158.5px; left: 0; }
				.pv-27 { bottom: 217.5px; left: 160.5px; }
				.pv-28 { bottom: 167px; right: 217.5px; }
				.pv-29 { bottom: 205.5px; right: 121px; }
				.pv-30 { bottom: 213.5px; right: 21px; }

				.pv-31 { bottom: 115px; left: 20px; }
				.pv-32 { bottom: 92px; left: 113px; }
				.pv-33 { bottom: 77px; right: 113.5px; }
				.pv-34 { bottom: 93.5px; right: 8px; }

				.pv-35 { bottom: 0.5px; left: 15px; }
				.pv-36 { bottom: 35.5px; right: 208px; }
				.pv-37 { bottom: 31.5px; right: 8px; }
			}

		}

		.friend-wrapper {
			display: flex;
    		justify-content: center;
			color: #969DA6;
			position: absolute;
			width: 100%;
			height: 100vh;
			left: 0;
			right: 0;
			opacity: 0;

			.friend-ipad {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100vh;
				overflow: hidden;
			}

			.friend-wrap {
				overflow: hidden;
				position: absolute;
				width: 2432px;
				height: 1884px;
				top: -8.78%;
				left: calc(50% - 2432px / 2);
			}

			.ipad-mock {
				position: absolute;
				will-change: transform;
				width: 2432px;
				height: auto;
				top: -8.78%;
				left: calc(50% - 2432px / 2);
			}

			.friend-bg {
				position: absolute;
				will-change: transform;
				width: 15000px;
				height: auto;
				top: -1950px;
				right: -5686px;
				opacity: .4;
			}

			.friend-header {
				position: absolute;
				top: 48.14%;
				left: 11.04%;
				opacity: 0;
				color: #F4F5F6;
				font-size: 29.149px;
				font-weight: 700;
				line-height: 40.808px;
				letter-spacing: -0.448px;
			}

			.friend-title {
				position: absolute;
				opacity: 0;
				color: #F4F5F6;
				font-size: 89.689px;
				font-weight: 700;
				line-height: 134.533px;
				letter-spacing: -0.448px;
			}

			.friend-text {
				position: absolute;
				opacity: 0;
				color: #FFF;
				font-size: 35.875px;
				font-weight: 400;
				line-height: 50.226px;
				letter-spacing: -0.448px;

				b { color: #F4F5F6; }
			}

			.friend-title-2 {
				position: absolute;
				opacity: 0;
				color: #969DA6;
				font-size: 62.268px;
				font-weight: 700;
				line-height: 93.402px;
				letter-spacing: -0.311px;
			}

			.friend-text-2 {
				position: absolute;
				opacity: 0;
				color: #969DA6;
				font-size: 24.907px;
				font-weight: 400;
				line-height: 34.87px;
				letter-spacing: -0.311px;
			}

			span {
				color: #F4F5F6;
			}

			.ft-1 { top: 60.15%; left: 11.04%; }
			.ft-2 { top: 65.62%; left: 11.04%; }
			.ft-3 { top: 91.21%; left: 11.04%; }
			.ft-4 { top: 93.65%; left: 11.04%; }
			.ft-5 { top: 96.09%; left: 11.04%; }

			.ft-6 { top: 49.21%; }
			.ft-7 { top: 57.27%; }
			.ft-8 { top: 68.79%; }
			.ft-9 { top: 73.24%; }
			.ft-10 { top: 77.78%; }
			.ft-11 { top: 84.32%; }
		}

	}
}

/* @ Landing (Desktop) */
// H: 80 / 1024 * 100 = 84.326171875 = 77.783203125 = 73.2421875 = 68.798828125 = 57.275390625 = 49.21875 = 21.38671875 = 4.00390625 = 3.125 = 8.984375 = 25.48828125 = 37.01171875 = 42.96875 = 19.921875 = 8.7890625 = 67.08984375 = 59.9609375 = 54.1015625 = 37.59765625 = 26.07421875 = 20.01953125 = 96.09375 = 93.65234375 = 91.2109375 = 65.625 = 60.15625 = 48.14453125 = 45.3125 = 56.640625 = 67.578125 = 18.5546875 = 153.515625 = 142.3828125 = 132.568359375 = 128.41796875 = 56.4453125 = 46.630859375 = 42.48046875 = 104.4921875 = 61.9140625 = 53.7109375 = 43.896484375 = 31.8359375 =
// W: 1004 / 1440 * 100 = 69.72222222222221 = 11.041666666666666 = 5 = 32.48611111111111 = 26.44097222222222 = 7.5 = 5 = 35.27777777777778 = 29.20138888888889 = 10.277777777777777 =

// 20000 / 3617 = 5.52944429084877

@keyframes border-animate {
	0% { left: 1px; top: 1px; }
	25% { left: calc(100% - 51px); top: 1px; }
	50% { left: calc(100% - 51px); top: calc(100% - 21px); }
	75% { left: 1px; top: calc(100% - 21px); }
	100% { left: 1px; top: 1px; }
}

/* ![Deprecated] */
.w-btn,
.w-btn-outline {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-height: 40px;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 16px;
	padding-right: 16px;
	border-radius: 8px;

	font-family: inherit;
	font-size: 16px;
	font-weight: 700;
	line-height: 22.4px;
	letter-spacing: -0.2px;
}

.w-btn {
	color: #090A0B;
	border: 1px solid #D9B968;
	background: linear-gradient(90deg, #BA8A46 0%, #F5E386 50%, #BA8A46 100%);
	box-shadow: -8px -8px 10px 0px rgba(30, 25, 0, 0.33), 10px 10px 15px 0px rgba(27, 19, 0, 0.54), -4px -4px 6px 0px rgba(247, 220, 121, 0.40), 4px 4px 8px 0px rgba(56, 44, 0, 0.90);
}

.w-btn-outline {
	color: #D9B968;
	border: 1px solid rgba(186, 138, 70, 0.00);
	background: #17191B;
	box-shadow: -8px -8px 10px 0px rgba(48, 51, 57, 0.23), 10px 10px 12px 0px rgba(0, 0, 0, 0.60), -4px -4px 6px 0px rgba(255, 255, 255, 0.10), 4px 4px 8px 0px rgba(0, 0, 0, 0.90);
}

.w-form {

	.login-forgot {
		display: block;
		width: 100%;
		text-align: center;
		color: #D9B968;
		text-decoration: underline;
		font-size: 16px;
		font-weight: 700;
		line-height: 22.4px;
		letter-spacing: -0.2px;
		margin-bottom: 1.5rem;
	}

	&.landing-register {
		left: calc(100% + 40px);
		transition: .5s ease-in-out;

		&.active {
			left: calc(50% - 382px / 2);
		}
	}

	&.landing-login {
		right: calc(100% + 40px);
		transition: .5s ease-in-out;

		&.active {
			right: calc(50% - 382px / 2);
		}
	}
}

/* @ Utils */
.text--primary {
	color: #D9B968 !important;
}

.text-black-600 {
	color: #7A8087;
}

.text-white-100 {
	color: #F4F5F6;
}

.text-highlight > span {
	color: #F4F5F6;
	font-weight: 700;
}

// Custom Responsive Media
@media (max-width: 1400px) {

	.landing-desktop .frame-3 .provider-wrapper {

		.provider-header {
			font-size: clamp(16px, 4.5vw, 25.146px);
			line-height: normal;
			letter-spacing: normal;
		}

		.provider-title {
			font-size: clamp(24px, 4.5vw, 77.373px);
			line-height: normal;
			letter-spacing: normal;
		}

	}

}

@media (max-width: 1300px) {

	.landing-desktop .frame-3 .provider-wrapper {

		.pvt-2 {
			margin-left: -1rem;
		}

		.pvt-4,
		.pvt-5 {
			margin-left: -2rem;
		}

	}

}

@media (max-width: 1200px) {

	.landing-desktop .frame-3 .provider-wrapper {

		.provider-header {
			font-size: 20px;
		}

		.provider-title {
			font-size: 40px;
		}

		.pvt-2 {
			margin-left: -4rem;
		}

		.pvt-4,
		.pvt-5 {
			margin-left: -5rem;
		}

	}

}

/* @ Deprecated */

.aa {
	height: 1800px;
}

.test-container {
	// padding: 20px;
}
