.promotion--PC {
  display: block;
}
.Promotion--Mb {
  display: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #eeaf4a;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #eeaf4a;
}

.promotion--PC {
  width: 80%;
  position: relative;
  margin-left: 10%;
  margin-top: 2%;
}
img.Pro--Games {
  width: 15%;
  margin-top: 4%;
  margin-inline-start: 25%;
}
img.Bg--Tab--Text {
  width: 50%;
  margin-left: 25%;
  margin-top: 1%;
}
.promotion--PC ul.nav.nav-tabs {
  justify-content: space-around;
  z-index: 1;
  height: 33px;
  --bs-nav-tabs-border-width: 0px;
  font-size: 15px;
  width: 50%;
  margin-left: 25%;
  padding: 5px 20px 0 20px;
  margin-top: -3%;
}
.nav-link {
  color: #ffff;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #ff000000;
  color: #eeaf4a;
}
.nav {
  --bs-nav-link-hover-color: #ffff;
}
.tab-content {
  width: 50%;
  margin-left: 25%;
}
img.w-100 {
  margin-top: 5%;
}
img.icon--Games--All {
  width: 20%;
  margin-left: 40%;
  margin-top: 2%;
  padding-bottom: 10%;
}
@media screen and (max-width: 1920px) {
  .promotion--PC {
    width: 80%;
    position: relative;
    margin-left: 10%;
    margin-top: 2%;
  }
  img.Pro--Games {
    width: 15%;
    margin-top: 4%;
    margin-inline-start: 25%;
  }
  img.Bg--Tab--Text {
    width: 50%;
    margin-left: 25%;
    margin-top: 1%;
  }
  .promotion--PC ul.nav.nav-tabs {
    justify-content: space-around;
    z-index: 1;
    height: 33px;
    --bs-nav-tabs-border-width: 0px;
    font-size: 15px;
    width: 50%;
    margin-left: 25%;
    padding: 5px 20px 0 20px;
    margin-top: -3%;
  }
  .nav-link {
    color: #ffff;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    background-color: #ff000000;
    color: #eeaf4a;
  }
  .nav {
    --bs-nav-link-hover-color: #ffff;
  }
  .tab-content {
    width: 50%;
    margin-left: 25%;
  }
  img.w-100 {
    margin-top: 5%;
  }
  img.icon--Games--All {
    width: 20%;
    margin-left: 40%;
    margin-top: 2%;
    padding-bottom: 10%;
  }
}
@media screen and (max-width: 1620px) {
  .promotion--PC ul.nav.nav-tabs {
    font-size: 11px;
  }
}
@media screen and (max-width: 1530px) {
  .promotion--PC ul.nav.nav-tabs {
    padding: 2px 20px 0 20px;
  }
}
@media screen and (max-width: 1220px) {
  .promotion--PC ul.nav.nav-tabs {
    font-size: 10px;
    padding: 0px 20px 0 20px;
  }
}
@media screen and (max-width: 920px) {
  .promotion--PC ul.nav.nav-tabs {
    font-size: 9px;
    padding: 5px 20px 0 20px;
    margin-top: -4%;
  }
}
@media screen and (max-width: 735px) {
  .promotion--PC ul.nav.nav-tabs {
    padding: 5px 17px 0 17px;
}
}
@media screen and (max-width: 720px) {
  .promotion--PC ul.nav.nav-tabs {
    padding: 3px 0px 0 0px;
  }
}
@media screen and (max-width: 706px) {
  .promotion--PC ul.nav.nav-tabs {
    padding: 3px 10px 0 10px;
}
}
@media screen and (max-width: 685px) {
  .promotion--PC ul.nav.nav-tabs {
    padding: 0px 5px 0 5px;;
  }
}
@media screen and (max-width: 660px) {
  .promotion--PC ul.nav.nav-tabs {
    padding: 0px 0px 0 0px;;
  }
}
@media screen and (max-width: 653px) {
  .promotion--PC ul.nav.nav-tabs {
    padding: 1px 42px 0 36px;
    width: 60%;
    margin-left: 20%;
    font-size: 7px;
}
}
@media screen and (max-width: 641px) {
  .promotion--PC ul.nav.nav-tabs {
    padding: 1px 130px 0 130px;
    width: 100%;
    margin-left: 0%;
    font-size: 7px;
}
}
@media screen and (max-width: 604px) {
  .promotion--PC ul.nav.nav-tabs {
    padding: 1px 120px 0 120px;
}
}
@media screen and (max-width: 594px) {
  .promotion--PC ul.nav.nav-tabs {
    padding: 1px 120px 0 120px;
}
}

@media screen and (max-width: 579px) {
  .promotion--PC ul.nav.nav-tabs {
    padding: 1px 110px 0 110px;
}
}
@media screen and (max-width: 569px) {
  .promotion--PC ul.nav.nav-tabs {
    padding: 1px 100px 0 100px;
}
}
@media screen and (max-width: 519px) {
  .promotion--PC ul.nav.nav-tabs {
    padding: 1px 95px 0 95px;
}
}
@media screen and (max-width: 506px) {
  .promotion--PC ul.nav.nav-tabs {
    padding: 1px 90px 0 90px;
}
}
@media screen and (max-width: 494px) {
  .promotion--PC ul.nav.nav-tabs {
    padding: 0px 80px 0 80px;
}
}
@media screen and (max-width: 480px) {
  .promotion--PC {
    display: none;
  }
  .Promotion--Mb {
    display: block;
  }
  .Promotion--Mb {
    width: 90%;
    position: absolute;
    margin-left: 5%;
    margin-top: 28%;
    top: 0%;
  }
  img.Pro--Games {
    width: 50%;
    margin-top: 4%;
    margin-inline-start: 0%;
  }
  img.Bg--Tab--Text {
    width: 100%;
    margin-left: 0%;
    margin-top: 5%;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border-color: #ffffff00;
  }
  .Promotion--Mb ul.nav.nav-tabs {
    justify-content: space-around;
    z-index: 1;
    height: 33px;
    --bs-nav-tabs-border-width: 0px;
    font-size: 10px;
    width: 100%;
    margin-left: 0%;
    padding: 8px 60px 0 60px;
    margin-top: -8%;
  }
  .tab-content {
    width: 90%;
    margin-left: 5%;
    padding-bottom: 50%;
  }
}
@media screen and (max-width: 415px) {
  .Promotion--Mb ul.nav.nav-tabs {
    font-size: 8px;
  }
}
@media screen and (max-width: 400px) {
  .Promotion--Mb ul.nav.nav-tabs {
    padding: 5px 60px 0 60px;
  }
}
@media screen and (max-width: 385px) {
  .Promotion--Mb ul.nav.nav-tabs {
    padding: 6px 60px 0 60px;
    font-size: 6px;
  }
}

@media screen and (max-width: 360px) {
  .Promotion--Mb ul.nav.nav-tabs {
    font-size: 5px;
  }
}
@media screen and (max-width: 347px) {
  .Promotion--Mb ul.nav.nav-tabs {
    font-size: 4px;
  }
}
