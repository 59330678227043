@import './env.scss';

/* @ Player */

.player-wrapper {
 min-height: 100vh;
}

/* @ Deposit */

.inner-container.payment-container {
	padding: 0;
    width: 100vw;
    max-width: none;
    margin: 0;

	.w-announce-running {
		margin-left: 24px;
		margin-right: 24px;
		width: calc(100% - 48px);
	}
}

/* @ Form */

.w-form {
	position: absolute;
	z-index: 1;
	width: 382px;
	height: auto;
	border-radius: 24px;
	border: none;
	background-color: #17191B;
	box-shadow: -8px -8px 10px 0px rgba(48, 51, 57, 0.23), 10px 10px 12px 0px rgba(0, 0, 0, 0.60), -4px -4px 6px 0px rgba(255, 255, 255, 0.10), 4px 4px 8px 0px rgba(0, 0, 0, 0.90);
	padding: 32px 32px 24px;
	top: 50%;
	transform: translateY(-50%);

	&.form-static {
		position: relative;
		top: auto;
		transform: none;
	}

	& > .form-title {
		text-align: center;
		margin-bottom: 1rem;
	}

	& > .form-close {
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 1rem;
		right: 1rem;

		& > .close-icon {
			position: relative;
			width: 16.5px;
			height: 16.5px;
		}

		& > .close-border {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&::before {
		width: 100%;
		height: 100%;
		content: ' ';
		position: absolute;
		inset: 0;
		z-index: -1;
		padding: 2px;
		border-radius: inherit;
		background: linear-gradient(180deg, #F5E386 0%, rgba(186, 138, 70, 0) 100%);
		-webkit-mask: linear-gradient(#FFF 0 0) content-box, linear-gradient(#FFF 0 0);
		mask: linear-gradient(#FFF 0 0) content-box, linear-gradient(#FFF 0 0);
		-webkit-mask-composite: xor;
		mask-composite: exclude;
	}
}

/* @ Welcome */

.welcome-wrapper {
	min-height: 382px;

	.welcome-border-top {
		position: absolute;
		top: 24px;
		left: 0;
	}

	.welcome-border-bottom {
		position: absolute;
		bottom: 24px;
		left: 0;
	}

	.welcome-play {
		width: 150px;
		height: 150px;
		position: absolute;
		top: -24px;
		left: -16px;
	}

	.welcome-avatar {
		width: 244px;
		height: 244px;
		position: absolute;
		bottom: 12px;
		right: -45px;
	}

	.welcome-logo {
		width: 81.504px;
		height: 53.6px;
		position: absolute;
		bottom: 49.5px;
		left: 23px;
	}
}

/* @ Add Bank */

.form-add-bank {
	.add-bank-title {
		color: #F4F5F6;
		font-size: 23px;
		font-weight: 700;
		line-height: 34.5px;
		letter-spacing: -0.2px;
		margin-bottom: 8px;
		text-align: center;
	}

	.add-bank-subtitle {
		color: #7A8087;
		font-size: 13px;
		line-height: 18.2px;
		letter-spacing: -0.2px;
		margin-bottom: 16px;
		text-align: center;
	}
}

/* @ Withdraw Wallet */

.form-withdraw-wallet {
	.form-title {
		color: #F4F5F6;
		font-size: 23px;
		font-weight: 700;
		line-height: 34.5px;
		letter-spacing: -0.2px;
		margin-bottom: 22px;
	}

	.form-card {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 15px;
		padding-right: 22px;
		height: 54px;
		border-radius: 8px;
		background: #17191B;
		box-shadow: -2px -2px 6px 0px rgba(92, 92, 92, 0.37) inset, 4px 4px 6px 0px #000 inset;
		margin-bottom: 10px;
	}

	.form-card-text {
		color: #F4F5F6;
		font-size: 16px;
		font-weight: 700;
		line-height: 22.4px;
		letter-spacing: -0.2px;
	}

	.form-card-amount {
		color: #D9B968;
		font-size: 16px;
		font-weight: 700;
		line-height: 22.4px;
		letter-spacing: -0.2px;
	}

	.form-min {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 14px;
	}

	.form-min-text {
		color: #F00;
		font-size: 12px;
		font-weight: 700;
	}

	.form-min-amount {
		color: #F00;
		font-size: 12px;
		font-weight: 500;
	}

	.form-input {
		margin-bottom: 20px;
	}
}

/* @ Change Password */

.form-change-password {
	.form-title {
		color: #F4F5F6;
		font-size: 23px;
		font-weight: 700;
		line-height: 34.5px;
		letter-spacing: -0.2px;
		margin-bottom: 8px;
	}

	.form-subtitle {
		color: #7A8087;
		font-size: 13px;
		line-height: 18.2px;
		letter-spacing: -0.2px;
		text-align: center;
		margin-bottom: 1rem;
	}
}

/* @ Forgot Password */

.form-forgot {
	.form-title {
		font-size: 23px;
		font-weight: 700;
		line-height: 34.5px;
		letter-spacing: -0.2px;
		margin-bottom: 8px;
	}

	.form-subtitle {
		color: #7A8087;
		font-size: 13px;
		line-height: 18.2px;
		letter-spacing: -0.2px;
		margin-bottom: 16px;
	}

	.form-label {
		font-size: 16px;
		line-height: 22.4px;
		letter-spacing: -0.2px;
	}
}

.form-forgot-receive {
	text-align: center;

	.forgot-title {
		color: #F4F5F6;
		font-size: 23px;
		font-weight: 700;
		line-height: 34.5px;
		letter-spacing: -0.2px;
		margin-bottom: 8px;
	}

	.forgot-subtitle {
		color: #7A8087;
		font-size: 13px;
		line-height: 18.2px;
		letter-spacing: -0.2px;
		margin-bottom: 32px;
	}

	.forgot-phone {
		color: #D9B968;
		font-size: 23px;
		font-weight: 700;
		line-height: 34.5px;
		letter-spacing: -0.2px;
		margin-bottom: 32px;
	}

	.forgot-footer {
		color: #F4F5F6;
		font-size: 13px;
		line-height: 18.2px;
		letter-spacing: -0.2px;
	}
}

/* @ Wallet */

.wall-container {
	max-width: none;
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
	padding-left: 60px;
	padding-right: 60px;
}

.wall-inner-container {
	display: flex;
	margin-top: 49px;

	& > .wall-start {
		flex: 0 0 648px;
		width: 648px;
	}

	.wall-navbar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 8px;
		background-color: #17191B;
		height: 48px;
		padding-left: 16px;
		padding-right: 23px;
		box-shadow: -6px -6px 10px 0px rgba(255, 255, 255, 0.10), 6px 6px 12px 0px rgba(0, 0, 0, 0.90);
		margin-bottom: 22px;
	}

	.wall-navbar-title {
		color: #FFF;
		font-size: 23px;
		font-weight: 700;
		line-height: 34.5px;
		letter-spacing: -0.2px;
	}

	.wall-navbar-link {
		color: #D9B968;
		font-size: 13px;
		font-weight: 700;
		line-height: 18.2px;
		letter-spacing: -0.2px;
	}

	.wall-category {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 27px;
	}

	.wall-promotion .swiper-slide {
		width: 403.015px;
		height: 261.643px;

		& > button {
			width: 100%;
			height: 100%;
		}

		.promotion-banner {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	& > .wall-center {
		flex: 0 0 200px;
		width: 200px;
		margin-left: 29px;
		margin-right: 22px;
	}

	.wall-menus {
		flex-wrap: wrap;
		justify-content: flex-start;
		gap: 16px 32px;
	}

	.wall-menu-item {
		max-width: 83px;
		height: 96px;
	}

	& > .wall-end {
		flex: 1 0 424px;
		width: 424px;
	}

	.wall-profile {
		margin-bottom: 1rem;
	}

	.wall-forgot-wrapper {
		margin-bottom: 17px;
		gap: 22px;
	}

	.wall-receive {
		flex: 0 0 calc(50% - 11px);
		min-height: 32px;
	}

	.wall-forgot-password {
		flex: 0 0 calc(50% - 11px);
		min-height: 32px;
		padding-top: 0;
		padding-bottom: 0;
		font-size: 13px;
		line-height: 18.2px;
		letter-spacing: -0.2px;
	}

	.wall-accordion > .accordion-item {
		border-radius: 12px;
		border-bottom: 1px solid #BA8A46;
		background: #17191B;
		box-shadow: -8px -8px 10px 0px rgba(48, 51, 57, 0.23), 10px 10px 12px 0px rgba(0, 0, 0, 0.60), -4px -4px 6px 0px rgba(255, 255, 255, 0.10), 4px 4px 8px 0px rgba(0, 0, 0, 0.90);
	}

	.wall-accordion .accordion-button {
		font-size: 16px;
		font-weight: 700;
		line-height: 22.4px;
		letter-spacing: -0.2px;
	}
	.wall-accordion-head {
		display: flex;
		align-items: center;
		width: 100%;
		margin-right: 10px;
	}

	.wall-accordion .accordion-body {
		padding: 0 16px 24px;
	}

	.wall-profit {
		flex: 1 0;
		height: 32px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		border-radius: 8px;
		background: #17191B;
		padding-left: 13px;
		padding-right: 13px;
		margin-left: 7px;
		box-shadow: -2px -2px 6px 0px rgba(92, 92, 92, 0.37) inset, 4px 4px 6px 0px #000 inset;

		color: #D9B968;
		font-size: 13px;
		font-weight: 700;
		line-height: 18.2px;
		letter-spacing: -0.2px;
	}

	.wall-balance-wrapper {
		display: flex;
		align-items: center;
		height: 32px;
		padding-left: 16px;
		border-radius: 8px;
		background: #17191B;
		box-shadow: -2px -2px 6px 0px rgba(92, 92, 92, 0.37) inset, 4px 4px 6px 0px #000 inset;

		.balance-text {
			color: #F4F5F6;
			font-size: 13px;
			line-height: 18.2px;
			letter-spacing: -0.2px;
		}

		.balance-amount {
			flex: 1 0;
			text-align: right;
			color: #D9B968;
			font-size: 13px;
			font-weight: 700;
			line-height: 18.2px;
			letter-spacing: -0.2px;
			margin-right: 10px;
		}

		.balance-btn {
			min-height: 32px;
			font-size: 13px;
			font-weight: 700;
			line-height: 18.2px;
			letter-spacing: -0.2px;
			padding-top: 0;
			padding-bottom: 0;
		}

		.balance-expired {
			color: #F84949;
			width: 90px;
			font-size: 12px;
			font-weight: 500;
			line-height: normal;
		}

		& + .wall-balance-wrapper {
			margin-top: 8px;
		}
	}

	.wall-credit-history {
		margin-top: 9px;
		min-height: 32px;
		padding-top: 0;
		padding-bottom: 0;
		font-size: 13px;
		font-weight: 700;
		line-height: 18.2px;
		letter-spacing: -0.2px;
		margin-bottom: 13px;
	}

	.wall-mark {
		color: #FFF;
		font-size: 11px;
		line-height: 14.3px;

		& + .wall-mark {
			margin-top: 9px;
		}
	}

	.wall-divide {
		margin-top: 25px;
		margin-bottom: 22px;
		height: 8px;
		width: 100%;
		border-radius: 4px;
		background-color: #17191B;
		box-shadow: -2px -2px 6px 0px rgba(255, 255, 255, 0.15) inset, 2px 2px 4px 0px #000 inset;
	}

	.wall-missionary {
		width: 100%;
		padding: 0;
		min-height: initial;
		background: none;
		box-shadow: none;
		border: none;
		border-radius: 0;
	}

}

/* @ Mock Navbar */

.mock-navbar {
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 382px;
	border-radius: 8px;
	border: 1px solid #17191B;
	background: #17191B;
	box-shadow: -8px -8px 10px 0px rgba(48, 51, 57, 0.23), 10px 10px 12px 0px rgba(0, 0, 0, 0.60), -4px -4px 6px 0px rgba(255, 255, 255, 0.10), 4px 4px 8px 0px rgba(0, 0, 0, 0.90);
	padding-left: 8px;
	padding-right: 8px;
	margin-left: auto;
	margin-right: auto;

	& > .mock-title {
		color: #F4F5F6;
		font-size: 19px;
		font-weight: 700;
		line-height: 28.5px;
		letter-spacing: -0.2px;
	}

	& > .mock-back {
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		& > svg {
			position: relative;
		}

		&::before {
			content: ' ';
			position: absolute;
			width: 100%;
			height: 100%;
			background-image: url('#{$image-root-path}/images/icons/close-border.webp');
			background-size: cover;
			background-position: center;
		}

		&:hover::before {
			animation: animate-spinner 2s linear infinite;
		}
	}

	& > .mock-close {
		color: #F4F5F6;
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		& > svg {
			position: relative;
			z-index: 1;
		}

		&::before {
			content: ' ';
			position: absolute;
			width: 100%;
			height: 100%;
			background-image: url('#{$image-root-path}/images/icons/border-red.svg');
			background-size: cover;
			background-position: center;
			transition: opacity 1s ease;
		}

		&::after {
			content: ' ';
			position: absolute;
			opacity: 0;
			width: 100%;
			height: 100%;
			background-image: url('#{$image-root-path}/images/icons/close-border.webp');
			background-size: cover;
			background-position: center;
			transition: opacity 1s ease;
		}

		&:hover::before {
			opacity: 0;
		}

		&:hover::after {
			opacity: 1;
			animation: animate-spinner 2s linear infinite;
		}
	}
}

/* @ Contact (Desktop) */

.contact-container {
	.w-announce-running {
		margin-bottom: 1rem;
	}

	.contact-logo {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 80px;
		height: 80px;
		border-radius: 40px;
		border: 1px solid #25272A;
		background: #17191B;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 1rem;
		box-shadow: -8px -8px 10px 0px rgba(48, 51, 57, 0.23), 10px 10px 12px 0px rgba(0, 0, 0, 0.60), -4px -4px 6px 0px rgba(255, 255, 255, 0.10), 4px 4px 8px 0px rgba(0, 0, 0, 0.90);

		& > img {
			width: 60px;
			height: 60px;
			top: -2px;
			position: relative;
		}
	}

	.contact-text {
		color: #7A8087;
		font-size: 13px;
		font-weight: 400;
		line-height: 18.2px;
		letter-spacing: -0.2px;
		margin-bottom: 20px;
		text-align: center;
	}

	.contact-card {
		max-width: 382px;
		padding: 16px 32px 24px;
		border-radius: 8px;
		border: 1px solid #25272A;
		background: #17191B;
		box-shadow: -8px -8px 10px 0px rgba(48, 51, 57, 0.23), 10px 10px 12px 0px rgba(0, 0, 0, 0.60), -4px -4px 6px 0px rgba(255, 255, 255, 0.10), 4px 4px 8px 0px rgba(0, 0, 0, 0.90);
		margin-bottom: 20px;
		margin-left: auto;
		margin-right: auto;
	}

	.contact-card-title {
		color: #C1C1C1;
		text-align: center;
		font-size: 16px;
		font-weight: 700;
		line-height: 22.4px;
		letter-spacing: -0.2px;
		margin-bottom: 18px;
	}

	.contact-footer {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 20px;

		& > .footer-text {
			color: #7A8087;
			font-size: 13px;
			line-height: 18.2px;
			letter-spacing: -0.2px;
		}

		& > .line-id {
			color: #D9B968;
			font-size: 16px;
			font-weight: 700;
			line-height: 22.4px;
			letter-spacing: -0.2px;
		}
	}
}



// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {

	/* @ Wallet */

	.wall-container {
		width: auto;
		padding-left: 24px;
        padding-right: 24px;
	}

	.wall-inner-container {
		margin-top: 0;
		flex-direction: column;

		& > .wall-end {
			order: 1;
			flex: 0 0 auto;
			width: 100%;
		}

		& > .wall-center {
			order: 2;
			flex: 0 0 auto;
			width: 100%;
			margin-left: 0;
			margin-right: 0;
		}

		& > .wall-start {
			flex: 0 0 auto;
			width: 100%;
			order: 3;
		}

		.wall-menus {
			height: auto;
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			gap: 16px 17px;
			margin-bottom: 31px;
		}

		.wall-accordion > .accordion-item {
			margin-bottom: 22px;
		}

		.wall-navbar {
			border-radius: 8px;
			background: #17191B;
			box-shadow: -2px -2px 6px 0px rgba(92, 92, 92, 0.37) inset, 4px 4px 6px 0px #000 inset;
		}

		.wall-category {
			margin-top: 48px;
			gap: 24px 17px;
			flex-wrap: wrap;
			justify-content: center;
			margin-bottom: 25px;
		}

		.wall-promotion {
			& > .btn-a {
				width: 100%;
			}

			& > .btn-a + .btn-a {
				margin-top: 16px;
			}

			.promotion-banner {
				width: 100%;
			}
		}

		.box-game-provider {
			height: 125px !important;
			width: 205px !important;
		}

		.swiper-game-hit {
			margin-bottom: 24px;
		}

		.swiper-game-hit .swiper {
			height: auto;
			padding-bottom: 29px;
		}

		.swiper-button-group {
			height: 24px;
			padding-left: 0;
			padding-right: 0;
		}

		.swiper-button-prev,
		.swiper-button-next {
			min-width: initial;
			width: 24px !important;
			height: 24px !important;

			svg {
				width: 6.976px;
				height: 11.875px;
			}
		}

		.swiper-pagination-game-hit {
			height: 8px !important;
		}

		.swiper-pagination-progressbar-fill {
			height: 4px !important;
		}
	}

	/* @ Games */

	.game-container > .w-announce-running {
		margin-bottom: 0 !important;
	}

	.games-tabs {
		margin-top: 32px;
		gap: 19px;
		border-radius: 8px;
		height: 40px;
		margin-bottom: 24px;

		& > .games-item {
			min-width: 60px;
			height: 29px;
			font-size: 13px;
			font-weight: 700;
			line-height: 18.2px;
			letter-spacing: -0.2px;
		}

		& .games-default,
		& .games-hover {
			height: 29px;
			width: auto;
		}
	}

	.games-wrapper {
		width: auto;
		grid-template-columns: repeat(2, 1fr);
		gap: 16px 18px;

		.games-item {
			width: auto;
			min-height: 112px;
			border-radius: 8px;
			border-width: 1px;
			box-shadow: -8px -8px 10px 0px rgba(48, 51, 57, 0.23), 10px 10px 12px 0px rgba(0, 0, 0, 0.60), -4px -4px 6px 0px rgba(255, 255, 255, 0.10), 4px 4px 8px 0px rgba(0, 0, 0, 0.90);

			&:hover {
				box-shadow: -2px -2px 6px 0px rgba(247, 220, 121, 0.60), 2px 2px 6px 0px rgba(56, 44, 0, 0.90);
			}

			&::before {
				padding: 1px;
			}

			&::after {
				height: 2px;
				top: 76px;
			}
		}

		.games-head {
			height: 74px;
			margin-left: 2px;
			margin-right: 2px;
			margin-top: 2px;
			margin-bottom: 9px;
			width: calc(100% - 4px);

			&::after {
				right: 10px;
				bottom: -16px;
				width: 32px;
				height: 32px;
				background-size: 32px 32px;
			}
		}

		.games-name {
			font-size: 13px;
			line-height: 18.2px;
			letter-spacing: -0.2px;
			margin-left: 10px;
			margin-bottom: 9px;
		}
	}

	.subgames-list {
		grid-template-columns: repeat(3, 1fr);
		gap: 20px;
		max-width: none;
	}
		.subgames-item {
			height: max-content;
		}

	/* @ Notification (Mobile) */

	.notification-backdrop {
		backdrop-filter: unset;
		z-index: 200;
	}

	.notification-modal {
		max-width: 100%;
		top: calc(115px + 1rem);
		right: auto;
		height: calc(100% - 220px);
		padding: 0;
		box-shadow: none;

		& > .notification-tabs {
			max-width: 382px;
		}

		& > .notification-body {
			max-width: calc(382px + 46px);
			width: calc(382px + 46px);
			padding-left: 24px;
			padding-right: 24px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	/* @ How to Affiliate (Mobile) */

	.page-friend {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
        overflow-x: hidden;
	}

	.friend-container {
		gap: 0;
		flex-direction: column;
		overflow-y: auto;
        overflow-x: hidden;
		padding-left: 24px;
        padding-right: 24px;
        margin-left: -24px;
        margin-right: -24px;

		.friend-start {
			flex: 0 0 auto;
			padding: 18px;
			border-radius: 12px;
			box-shadow: -8px -8px 10px 0px rgba(48, 51, 57, 0.23), 10px 10px 12px 0px rgba(0, 0, 0, 0.60), -4px -4px 6px 0px rgba(255, 255, 255, 0.10), 4px 4px 8px 0px rgba(0, 0, 0, 0.90);
			margin-bottom: 24px;
		}

		.friend-title {
			font-size: 16px;
			line-height: 22.4px;
			letter-spacing: -0.2px;
			margin-bottom: 8px;
		}

		.friend-subtitle {
			font-size: 13px;
			line-height: 18.2px;
			letter-spacing: -0.2px;
			margin-bottom: 18px;
		}

		.how-title {
			font-size: 13px;
			line-height: 18.2px;
			letter-spacing: -0.2px;
			margin-bottom: 8px;
		}

		.how-subtitle {
			font-size: 13px;
			line-height: 18.2px;
			letter-spacing: -0.2px;
			margin-bottom: 13px;
		}

		.how-item {
			margin-left: 30px;
			padding: 17px 23px 16px 38px;
			min-height: 80px;
			border-radius: 12px;
			box-shadow: -8px -8px 10px 0px rgba(48, 51, 57, 0.23), 10px 10px 12px 0px rgba(0, 0, 0, 0.60), -4px -4px 6px 0px rgba(255, 255, 255, 0.10), 4px 4px 8px 0px rgba(0, 0, 0, 0.90);
		}

		.how-friend {
			width: 100%;
			margin-bottom: 26px;
		}

		.step-logo > img {
			width: 105px;
		}

		.step-logo > span {
			font-size: 24px;
			line-height: 20px;
		}

		.step-head {
			margin-bottom: 7px;
		}

		.step-title {
			font-size: 13px;
			line-height: 18.2px;
			letter-spacing: -0.2px;
		}

		.step-text {
			font-size: 13px;
			line-height: 18.2px;
			letter-spacing: -0.2px;
		}

		.step-no {
			min-width: 49px;
			font-size: 16px;
			line-height: 22.4px;
			letter-spacing: -0.2px;
			margin-right: 11px;
		}
	}

	.friend-container .question-title {
		font-size: 16px;
		line-height: 22.4px;
		letter-spacing: -0.2px;
		margin-bottom: 15px;
	}

	.question-accordion .question-divide {
		height: 4px;
		border-radius: 10px;
		box-shadow: -2px -2px 6px 0px rgba(255, 255, 255, 0.15) inset, 2px 2px 4px 0px #000 inset;
		margin-bottom: 19px;
	}

	.question-accordion .question-icon {
		margin-right: 8.75px;
		height: 15px;
	}
	.question-accordion .accordion-button {
		font-size: 13px;
		line-height: 18.2px;
		letter-spacing: -0.2px;
		height: 40px;
		padding-left: 16px;
		padding-right: 16px;
		padding-top: 10px;
		padding-bottom: 10px;
		border-radius: 12px !important;
	}
	.question-accordion .accordion-button::after {
		background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.99932 12.6667C7.72751 12.6667 7.45572 12.5626 7.24849 12.355L0.727147 5.81757C0.312305 5.40171 0.312305 4.72746 0.727147 4.31177C1.14182 3.89608 1.81428 3.89608 2.22916 4.31177L7.99932 10.0964L13.7695 4.31197C14.1844 3.89628 14.8568 3.89628 15.2714 4.31197C15.6864 4.72766 15.6864 5.40191 15.2714 5.81777L8.75016 12.3552C8.54282 12.5629 8.27104 12.6667 7.99932 12.6667Z' fill='url(%23paint0_linear_3630_90561)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_3630_90561' x1='0.416016' y1='8.33333' x2='15.5827' y2='8.33333' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23BA8A46'/%3E%3Cstop offset='0.5' stop-color='%23F5E386'/%3E%3Cstop offset='1' stop-color='%23BA8A46'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
	}
	.question-accordion>.accordion-item {
		border-radius: 12px !important;
		border-bottom: 2px solid #BA8A46 !important;
	}
	.question-accordion>.accordion-item+.accordion-item {
		margin-top: 16px;
	}
	.question-accordion .accordion-body {
		padding: 0 16px 25px;
		font-size: 11px;
		font-weight: 400;
		line-height: 14.3px;
	}

	/* @ History (Mobile) */

	.w-history-wrapper {
		.history-card + .history-card {
			margin-top: 16px;
		}
	}

	.bet-history-wrapper {
		.bet-card + .bet-card {
			margin-top: 16px;
		}

		.bet-card {
			min-height: 200px;
			border-radius: 12px;
			box-shadow: -8px -8px 10px 0px rgba(48, 51, 57, 0.23), 10px 10px 12px 0px rgba(0, 0, 0, 0.60), -4px -4px 6px 0px rgba(255, 255, 255, 0.10), 4px 4px 8px 0px rgba(0, 0, 0, 0.90);
		}

		.bet-head {
			padding: 16px 0 16px 16px;
			background: none !important;
		}

		.bet-head > .bet-id {
			font-size: 16px;
			line-height: 22.4px;
			letter-spacing: -0.2px;
		}

		.bet-head > .bet-time {
			font-size: 11px;
			line-height: 14.3px;
		}

		.bet-head > .bet-status {
			margin-top: 10px;
			height: 26px;
			font-size: 12px;
			width: 100px;
			border-radius: 6px;
		}

		.bet-body {
			margin-top: 0;
			gap: 29px;
			padding-left: 16px;
			padding-right: 16px;
		}

		.bet-row {
			--column-width: 60px;
			gap: 11px 0;

			& > .col-h {
				font-size: 11px;
				font-weight: 700;
				line-height: 14.3px;
			}

			& > .col-d {
				height: 32px;
				padding-top: 6px;
				padding-left: 9px;
				padding-right: 8px;
				font-size: 13px;
				line-height: 18.2px;
				letter-spacing: -0.2px;
				border-radius: 6px;
				box-shadow: -2px -2px 6px 0px rgba(92, 92, 92, 0.37) inset, 4px 4px 6px 0px #000 inset;
			}
		}
	}

	/* @ Affiliate (Mobile) */

	.page-affiliate {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
        overflow-x: hidden;
	}

	.friend-tabs,
	.history-tabs,
	.affiliate-tabs {
		width: 100%;
		height: 32px;
		border-radius: 8px;
		box-shadow: -2px -2px 6px 0px rgba(92, 92, 92, 0.37) inset, 4px 4px 6px 0px #000 inset;
		padding-left: 8px;
		padding-right: 8px;
		margin-bottom: 32px;
		margin-top: 0;

		& > .tab-item-new {
			height: 48px;
			border-radius: 8px;

			&::before {
				width: calc(100% - 10px);
				height: 38px;
				border-radius: 8px;
			}
		}

		& > .tab-item-new > span {
			font-size: 16px;
			font-weight: 700;
			line-height: 22.4px;
			letter-spacing: -0.2px;
		}
	}

	.w-affiliate-container {
		grid-template-columns: 1fr;
		overflow-y: auto;
        overflow-x: hidden;
        padding-left: 24px;
        padding-right: 24px;
        margin-left: -24px;
        margin-right: -24px;

		& > .affiliate-start {

			.title-credit-profile h4 {
				font-size: 23px;
				line-height: 34.5px;
				letter-spacing: -0.2px;
				margin-bottom: 13px;
			}

			.copy-text {
				height: 40px;
				max-width: 285px;
				border-radius: 8px;
				background: #17191B;
				box-shadow: -2px -2px 6px 0px rgba(92, 92, 92, 0.37) inset, 4px 4px 6px 0px #000 inset;
				font-size: 13px;
				line-height: 18.2px;
				letter-spacing: -0.2px;
				padding: 11px 16px;
			}

			.copy-btn {
				flex: 0 0 83px;
				width: 83px;

				font-size: 13px;
				font-weight: 400;
				line-height: 18.2px;
				letter-spacing: -0.2px;
			}

			.affiliate-copy-wrapper {
				margin-top: 32px;
				margin-bottom: 1rem;
				gap: 1rem;
			}
		}

		& > .affiliate-end {
			width: 100%;
			padding: 18px 16px 24px;
			border-radius: 12px;
			box-shadow: -8px -8px 10px 0px rgba(48, 51, 57, 0.23), 10px 10px 12px 0px rgba(0, 0, 0, 0.60), -4px -4px 6px 0px rgba(255, 255, 255, 0.10), 4px 4px 8px 0px rgba(0, 0, 0, 0.90);
			margin-bottom: 32px;

			& > .affiliate-title {
				font-size: 16px;
				line-height: 22.4px;
				letter-spacing: -0.2px;
				margin-bottom: 16px;
			}

			& > .affiliate-input {
				margin-bottom: 16px;
			}

			& > .affiliate-row {
				margin-bottom: 14px;
				gap: 16px 0;

				& > .col-d,
				& > .col-h {
					font-size: 13px;
					line-height: 18.2px;
					letter-spacing: -0.2px;
				}
			}

			& > .affiliate-divide {
				height: 4px;
				border-radius: 27px;
				box-shadow: -2px -2px 6px 0px rgba(255, 255, 255, 0.15) inset, 2px 2px 4px 0px #000 inset;
				margin-bottom: 10px;
			}

			& > .affiliate-profit {
				padding-left: 22px;
				padding-right: 18px;
				height: 40px;
				border-radius: 12px;
				margin-bottom: 8px;
				box-shadow: -2px -2px 6px 0px rgba(92, 92, 92, 0.37) inset, 4px 4px 6px 0px #000 inset;
			}

			.profit-title {
				font-size: 16px;
				line-height: 22.4px;
				letter-spacing: -0.2px;
			}

			.profit-text {
				font-size: 16px;
				line-height: 22.4px;
				letter-spacing: -0.2px;
			}

			.affiliate-overview {
				gap: 16px;
			}

			.affiliate-card {
				height: 88px;
				border-radius: 12px;
				box-shadow: -2px -2px 6px 0px rgba(92, 92, 92, 0.37) inset, 4px 4px 6px 0px #000 inset;
			}

			.overview-title {
				font-size: 13px;
				line-height: 18.2px;
				letter-spacing: -0.2px;
				margin-bottom: 12px;
			}

			.overview-text {
				font-size: 19px;
				line-height: 28.5px;
				letter-spacing: -0.2px;
			}
		}

		& > .affiliate-summary {
			.payment-summary {
				border-radius: 12px;
				padding: 16px;
				box-shadow: -8px -8px 10px 0px rgba(48, 51, 57, 0.23), 10px 10px 12px 0px rgba(0, 0, 0, 0.60), -4px -4px 6px 0px rgba(255, 255, 255, 0.10), 4px 4px 8px 0px rgba(0, 0, 0, 0.90);

				.summary-title {
					font-size: 16px;
					line-height: 22.4px;
					letter-spacing: -0.2px;
					margin-bottom: 26px;
				}

				.summary-row {
					gap: 17px;
				}

				.summary-card {
					height: 88px;
					border-radius: 12px;
					box-shadow: -2px -2px 6px 0px rgba(92, 92, 92, 0.37) inset, 4px 4px 6px 0px #000 inset;
				}

				.summary-text {
					font-size: 13px;
					line-height: 18.2px;
					letter-spacing: -0.2px;
					margin-bottom: 12px;
				}

				.summary-amount {
					font-size: 19px;
					line-height: 28.5px;
					letter-spacing: -0.2px;
				}
			}
		}
	}

	/* @ Promotion History (Mobile) */

	.promotion-history-container {
		padding-top: 0;
	}

	.promotion-history-wrapper {
		grid-template-columns: 1fr;
		gap: 18px 0;
		margin-bottom: 21px;
		margin-top: 0;

		.history-card {
			border-radius: 12px;
			border-bottom-width: 2px;
			padding: 10px 8px 20px;
			box-shadow: -8px -8px 10px 0px rgba(48, 51, 57, 0.23), 10px 10px 12px 0px rgba(0, 0, 0, 0.60), -4px -4px 6px 0px rgba(255, 255, 255, 0.10), 4px 4px 8px 0px rgba(0, 0, 0, 0.90);
		}

		.history-head {
			border-radius: 6px;
			padding-left: 12px;
			margin-bottom: 10px;
			box-shadow: -2px -2px 6px 0px rgba(92, 92, 92, 0.37) inset, 4px 4px 6px 0px #000 inset;
		}

		.history-title {
			font-size: 13px;
			line-height: 18.2px;
			letter-spacing: -0.2px;
		}

		.history-inner {
			& > .col-h {
				font-size: 13px;
				line-height: 18.2px;
				letter-spacing: -0.2px;
			}

			& > .col-d {
				font-size: 13px;
				line-height: 18.2px;
				letter-spacing: -0.2px;
			}
		}

		.history-status {
			right: -10px;
		}

		.history-badge {
			height: 20px;
			border-radius: 60px;
			border-width: 1px;
			box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.30);
			font-size: 10px;
		}

		.amount-wrapper {
			min-width: 116px;
    		min-height: 56px;
			border-radius: 12px;
			border-width: 2px;
			backdrop-filter: blur(3px);
		}

		.amount-title {
			font-size: 12px;
		}

		.amount-text {
			font-size: 18px;
		}
	}

	/* @ Promotion (Mobile) */

	.promotion-backdrop {
		z-index: 1400;

		&.modal-show ~ .w-bottom-nav {
			z-index: 1450;
		}
	}

	.promotion-popup {
		flex-direction: column;
		width: 100%;
		height: 100%;
		border-radius: 0;
		gap: 0;
		padding: 25px 24px 110px;
		box-shadow: none;

		.modal-start {
			margin-top: 1rem;
		}

		.modal-banner {
			width: calc(100% + 48px);
			height: auto;
			margin-left: -24px;
			margin-right: -24px;
		}

		.modal-tags {
			margin-top: 2px;
			margin-left: 12px;
			margin-bottom: 19px;
		}

		.modal-end {
			overflow: auto;
		}

		.modal-content {
			margin-top: 0;
		}

		.popup-close,
		.all-detail {
			display: none;
		}
	}

	.promotion-session {

		.promotion-header {
			flex-direction: column;

			.promotion-navigate {
				justify-content: flex-end;
			}

			.link-history {
				display: none;
			}

			.promotion-category  {
				flex-wrap: wrap;
				justify-content: center;
				gap: 17px;
			}

			.w-category {
				flex-basis: 30%;
				margin-bottom: 7px;
			}

			.promotion-missionary {
				width: 100%;
			}
		}

		.promotion-banner {
			width: 100%;
			margin-bottom: 16px;
		}
	}

}

@media (max-width: 576.98px) {
	/* @ Promotion (Mobile) */

	.promotion-session {

		.promotion-header {
			gap: 29px;

			.w-category {
				flex-basis: 116px;
				margin-bottom: 7px;
				min-width: 116px;
			}

			.promotion-category  {
				margin-bottom: 27px;
			}

			.promotion-switch {
				align-items: center;
			}

			.promotion-switch-text {
				font-size: 12px;
			}
		}
	}
}
