.wrapper.wrapper--bg {
  background: url("/src/Images/ImgPc/PcImg2.png") no-repeat;
  /* background: url("/src/Images/ImgPc/PcImg2.png") no-repeat; */
  background-size: cover;
  height: 100vh;
  max-width: 100%;
  background-position: center;
  position: fixed;
  width: 100%;
  overflow-y: auto;
}

@font-face {
  font-family: "sukhumvitSetMedium";
  /*Can be any text*/
  src: local("sukhumvitSet-Medium"),
    url("../Fonts/sukhumvit/ttf/SukhumvitSet-Medium.ttf") format("truetype");
}

* {
  font-family: sukhumvitSetMedium;
}

.header--PC {
  display: block;
}

.header--Mb {
  display: none;
}

.Close--menu--Pc {
  display: none;
}

.Close--Nofi--Pc {
  display: none;
}

img.Head--Pc {
  width: 100%;
  position: fixed;
  z-index: 2;
  top: 0%;
}

img.Icon--Head--Pc {
  width: 4%;
  position: fixed;
  margin-left: 3%;
  z-index: 3;
  top: 0px;
}

.Login--Regis--Pc {
  position: fixed;
  width: 24%;
  right: 6%;
  z-index: 3;
  top: 5px;
}

img.Register--Pc {
  width: 40%;
  padding: 2%;
}

img.Login--Pc {
  width: 40%;
  padding: 2%;
}

.Icon--Noti--Menu--Pc {
  position: fixed;
  width: 11%;
  right: -1%;
  z-index: 3;
  margin-top: 8px;
  top: 0%;
  cursor: pointer;
}

img.Icon--Notify--Pc {
  width: 30%;
  margin-right: 10%;
}

img.Icon--Menu--Pc {
  width: 30%;
  margin-left: 10%;
}

img.Tab--Menu--Pc {
  position: fixed;
  width: 4%;
  bottom: 0%;
  right: 1%;
}

img.Tab--Play--Icon--Pc {
  position: fixed;
  width: 7%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
}

.Icon--Games--Pc {
  position: fixed;
  width: 4%;
  right: 1%;
  bottom: 0%;
  margin-bottom: 11%;
  z-index: 2;
}

img.Icon--Wallet--Pc {
  width: 85%;
  margin-left: 8%;
  z-index: 2;
}

img.Icon--Deposit--Pc {
  width: 85%;
  margin-left: 8%;
  margin-top: 15%;
  z-index: 2;
}

img.Icon--Withdraw--Pc {
  width: 85%;
  margin-left: 8%;
  margin-top: 15%;
  z-index: 2;
}

img.Icon--Promotion--Pc {
  width: 85%;
  margin-left: 8%;
  margin-top: 15%;
  z-index: 2;
}

.Text--Show--Pc {
  display: flex;
  width: 100%;
  height: 96px;
  position: relative;
  top: 123px;
  left: 50%;
  transform: translate(-50%, -50%);
}

img.Tab--Bar--Text--Pc {
  width: 65%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 68%);
}

img.Close--Tab--Text--Pc {
  position: absolute;
  width: 2%;
  right: 16%;
  margin-top: 1%;
  margin-right: 25px;
  padding: 4px;
}

.text--tab--Pc {
  width: 62%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -25%);
  margin-top: 2%;
  color: white;
  font-size: 12px;
}

.menu {
  backdrop-filter: blur(5px);
  background-color: rgb(0 0 0 / 46%) !important;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 76px;
  width: 100%;
  left: 143%;
}

img.tx--1 {
  width: 70%;
  margin-left: 7%;
  margin-top: 3%;
}

.wrapper--menu--nofi {
  backdrop-filter: blur(5px);
  background-color: rgb(0 0 0 / 46%) !important;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  width: 300%;
  left: -100%;
  top: 0%;
  margin-left: 0%;
  margin-top: 9%;
}

h2.textnoti {
  font-size: 178%;
  display: flex;
  justify-content: center;
  color: #ffff;
  margin-top: 2%;
}

.fade.showmenu--modal.modal.show {
  --bs-modal-padding: 0;
  --bs-modal-margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  outline: 0;
}

.text--bg--menu {
  margin-top: 5%;
}

img.bg--tab--blue--nofi {
  width: 70%;
  margin-left: 15%;
  margin-top: 2%;
}

.menu--nofi ul.nav.nav-tabs {
  justify-content: space-around;
  z-index: 1;
  height: 33px;
  --bs-nav-tabs-border-width: 0px;
  font-size: 18px;
  width: 50%;
  margin-left: 25%;
  padding: 5px 20px 0 20px;
  margin-top: -4%;
}

.games.mb-2.col-12 {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10%;
  font-size: 20px;
  color: #ffff;
}

.fade.showmenu--nofi--modal.modal.show {
  overflow-x: hidden;
  overflow-y: hidden;
}

img.Icon--close--Nofi {
  position: fixed;
  right: 0%;
  width: 4%;
  margin-right: 13px;
  top: 0;
  margin-top: 2px;
}

img.Icon--close--Menu {
  position: fixed;
  right: 0%;
  width: 4%;
  margin-right: 13px;
  top: 0;
  margin-top: 2px;
}

@media screen and (max-width: 1920px) {
  img.Head--Pc {
    width: 100%;
    position: fixed;
    z-index: 2;
    top: 0%;
  }

  img.Icon--Head--Pc {
    width: 5%;
    position: fixed;
    margin-left: 2%;
    z-index: 3;
  }

  .Login--Regis--Pc {
    position: fixed;
    width: 24%;
    right: 6%;
    z-index: 3;
    top: 5px;
  }

  img.Register--Pc {
    width: 40%;
    padding: 2%;
  }

  img.Login--Pc {
    width: 40%;
    padding: 2%;
  }

  .Icon--Noti--Menu--Pc {
    position: fixed;
    width: 11%;
    right: -1%;
    z-index: 3;
    margin-top: 8px;
    top: 0%;
  }

  img.Icon--Notify--Pc {
    width: 30%;
    margin-right: 10%;
  }

  img.Icon--Menu--Pc {
    width: 30%;
    margin-left: 10%;
  }

  img.Tab--Menu--Pc {
    position: fixed;
    width: 4%;
    bottom: 0%;
    right: 1%;
  }

  img.Tab--Play--Icon--Pc {
    position: fixed;
    width: 7%;
    right: 0%;
    bottom: 0%;
    z-index: 2;
  }

  .Icon--Games--Pc {
    position: fixed;
    width: 4%;
    right: 1%;
    bottom: 0%;
    margin-bottom: 11%;
    z-index: 2;
  }

  img.Icon--Wallet--Pc {
    width: 85%;
    margin-left: 8%;
    z-index: 2;
  }

  img.Icon--Deposit--Pc {
    width: 85%;
    margin-left: 8%;
    margin-top: 15%;
    z-index: 2;
  }

  img.Icon--Withdraw--Pc {
    width: 85%;
    margin-left: 8%;
    margin-top: 15%;
    z-index: 2;
  }

  img.Icon--Promotion--Pc {
    width: 85%;
    margin-left: 8%;
    margin-top: 15%;
    z-index: 2;
  }

  .Text--Show--Pc {
    display: flex;
    width: 100%;
    height: 96px;
    position: relative;
    top: 123px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img.Tab--Bar--Text--Pc {
    width: 65%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 68%);
  }

  img.Close--Tab--Text--Pc {
    position: absolute;
    width: 2%;
    right: 16%;
    margin-top: 1%;
    margin-right: 25px;
    padding: 4px;
  }

  .text--tab--Pc {
    width: 62%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -25%);
    margin-top: 2%;
    color: white;
    font-size: 12px;
  }

  .menu {
    backdrop-filter: blur(5px);
    background-color: rgb(0 0 0 / 46%) !important;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 76px;
    width: 100%;
    left: 143%;
  }

  img.tx--1 {
    width: 70%;
    margin-left: 7%;
    margin-top: 3%;
  }

  .wrapper--menu--nofi {
    backdrop-filter: blur(5px);
    background-color: rgb(0 0 0 / 46%) !important;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    width: 300%;
    left: -100%;
    top: 0%;
    margin-left: 0%;
    margin-top: 9%;
  }

  h2.textnoti {
    font-size: 178%;
    display: flex;
    justify-content: center;
    color: #ffff;
    margin-top: 2%;
  }

  .fade.showmenu--modal.modal.show {
    --bs-modal-padding: 0;
    --bs-modal-margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-modal-zindex);
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    outline: 0;
  }

  .text--bg--menu {
    margin-top: 5%;
  }

  img.bg--tab--blue--nofi {
    width: 70%;
    margin-left: 15%;
    margin-top: 2%;
  }

  .menu--nofi ul.nav.nav-tabs {
    justify-content: space-around;
    z-index: 1;
    height: 33px;
    --bs-nav-tabs-border-width: 0px;
    font-size: 18px;
    width: 50%;
    margin-left: 25%;
    padding: 5px 20px 0 20px;
    margin-top: -4%;
  }

  .games.mb-2.col-12 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10%;
    font-size: 20px;
    color: #ffff;
  }

  .fade.showmenu--nofi--modal.modal.show {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  img.Icon--close--Nofi {
    position: fixed;
    right: 0%;
    width: 4%;
    margin-right: 13px;
    top: 0;
    margin-top: 2px;
  }

  img.Icon--close--Menu {
    position: fixed;
    right: 0%;
    width: 4%;
    margin-right: 13px;
    top: 0;
    margin-top: 2px;
  }
}

@media screen and (max-width: 1620px) {
  .text--tab--Pc {
    width: 58%;
  }

  img.Close--Tab--Text--Pc {
    margin-right: 22px;
  }

  .menu {
    left: 130%;
  }
}

@media screen and (max-width: 1420px) {
  .text--tab--Pc {
    font-size: 8px;
  }

  img.Close--Tab--Text--Pc {
    margin-right: 19px;
  }

  img.tx--1 {
    width: 50%;
  }
}

@media screen and (max-width: 1320px) {
  .menu {
    left: 115%;
  }
}

@media screen and (max-width: 1120px) {
  .menu {
    left: 100%;
  }

  img.tx--1 {
    width: 40%;
  }
}

@media screen and (max-width: 860px) {
  img.Icon--Head--Pc {
    top: 2px;
  }

  .Login--Regis--Pc {
    top: 2px;
  }

  .Icon--Noti--Menu--Pc {
    margin-top: 4px;
  }

  .text--tab--Pc {
    font-size: 5px;
  }

  img.Close--Tab--Text--Pc {
    right: 15%;
  }
}

@media screen and (max-width: 630px) {
  .Icon--Noti--Menu--Pc {
    margin-top: 0px;
  }

  .Login--Regis--Pc {
    top: 1px;
  }
}

@media screen and (max-width: 530px) {
  .Login--Regis--Pc {
    top: 0px;
  }

  .text--tab--Pc {
    font-size: 4px;
  }
}

@media screen and (max-width: 480px) {
  .header--PC {
    display: none;
  }

  .header--Mb {
    display: block;
  }

  .Close--menu--Pc {
    display: block;
  }

  .Close--Nofi--Pc {
    display: block;
  }

  .wrapper.wrapper--bg {
    background: url("/src/Images/ImgPc/ImgMb21.png") no-repeat;
    /* background: url("/src/Images/ImgMb/ImgMb21.png") no-repeat; */
    background-size: 100% 100%;
    height: 100vh;
    max-width: 100%;
    background-position: center;
    position: fixed;
    width: 100%;
    overflow-y: auto;
  }

  img.Head--Mb {
    width: 100%;
    position: fixed;
    top: 0%;
    z-index: 2;
  }

  img.Icon--Head--Mb {
    width: 18%;
    position: fixed;
    left: 0%;
    margin-left: 2%;
    margin-top: 0%;
    top: 0px;
    z-index: 3;
  }

  .Icon--Noti--Menu--Mb {
    position: fixed;
    width: 30%;
    right: -7%;
    top: 1%;
    z-index: 3;
  }

  img.Icon--Notify--Mb {
    width: 30%;
  }

  img.Icon--Menu--Mb {
    width: 30%;
    margin-left: 10%;
  }

  img.Footer--Mb {
    width: 100%;
    position: fixed;
    bottom: 0%;
    z-index: 2;
  }

  img.Tab--Play--Icon--Mb {
    position: fixed;
    width: 25%;
    right: 0%;
    bottom: 0%;
    z-index: 3;
  }

  .Icon--Games--Mb {
    position: fixed;
    bottom: 0%;
    width: 80%;
    margin-left: 10%;
    margin-bottom: 0%;
    z-index: 3;
  }

  img.Icon--Wallet--Mb {
    width: 15%;
  }

  img.Icon--Deposit--Mb {
    width: 15%;
    margin-left: 5%;
  }

  img.Icon--Withdraw--Mb {
    width: 15%;
    margin-left: 5%;
  }

  img.Icon--Promotion--Mb {
    width: 15%;
    margin-left: 5%;
  }

  img.Tab--Bar--Text--Mb {
    width: 90%;
    margin-left: 5%;
    margin-top: 20%;
  }

  img.Close--Tab--Text--Mb {
    width: 8%;
    margin-top: 20%;
    position: absolute;
    right: 5%;
    z-index: 1;
  }

  .text--tab--Mb {
    position: absolute;
    top: 0%;
    margin-top: 23%;
    font-size: 14px;
    width: 77%;
    margin-left: 10%;
    color: white;
  }

  img.HGames--Pro--Mb1 {
    width: 45%;
  }

  img.HGames--Pro--Mb2 {
    width: 45%;
    margin-left: 8%;
  }

  img.HGames--Pro--Mb3 {
    width: 45%;
  }

  img.HGames--Pro--Mb4 {
    width: 45%;
    margin-left: 8%;
  }

  img.bg--tab--blue--nofi {
    width: 40%;
    margin-left: 30%;
    margin-top: 2%;
  }

  .menu--nofi ul.nav.nav-tabs {
    justify-content: space-around;
    z-index: 1;
    height: 33px;
    --bs-nav-tabs-border-width: 0px;
    font-size: 12px;
    width: 30%;
    margin-left: 35%;
    padding: 9px 20px 0 20px;
    margin-top: -3%;
  }

  h2.textnoti {
    font-size: 120%;
    display: flex;
    justify-content: center;
    color: #ffff;
  }

  .games.mb-2.col-12 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5%;
    font-size: 15px;
    color: #ffff;
  }

  .menu {
    left: 20%;
    top: 0px;
    margin-top: 0%;
  }

  img.tx--1 {
    width: 70%;
    margin-left: 3%;
  }

  .text--bg--menu {
    margin-top: 10%;
  }

  img.Icon--close--Menu {
    position: fixed;
    right: 0%;
    width: 7%;
    margin-right: 1%;
    top: 0;
    margin-top: 1%;
    z-index: 1;
  }

  img.Icon--close--Nofi {
    position: fixed;
    right: 0%;
    width: 7%;
    margin-right: 1%;
    top: 0;
    margin-top: 1%;
    z-index: 1;
  }

  .wrapper--menu--nofi {
    width: 300%;
    left: -100%;
    top: 0%;
    margin-left: 0%;
    margin-top: -11px;
  }
}

@media screen and (max-width: 450px) {
  .menu--nofi ul.nav.nav-tabs {
    padding: 4px 20px 0 20px;
    margin-top: -3%;
  }
}

@media screen and (max-width: 360px) {
  .menu--nofi ul.nav.nav-tabs {
    font-size: 10px;
    padding: 2px 20px 0 20px;
  }

  h2.textnoti {
    font-size: 105%;
  }

  .games.mb-2.col-12 {
    font-size: 10px;
  }
}

@media screen and (max-width: 331px) {
  .menu--nofi ul.nav.nav-tabs {
    padding: 2px 15px 0 15px;
  }
}

@media screen and (max-width: 320px) {
  .menu--nofi ul.nav.nav-tabs {
    font-size: 8px;
  }
}