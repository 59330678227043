.home--Pc {
  display: block;
}
.home--Mb {
  display: none;
}

ul.splide__pagination.splide__pagination--ltr li button.is-active {
  background-color:rgb(218, 167, 0) !important;
}

.Banner--Pc {
  width: 100%;
}
ul.splide__pagination.splide__pagination--ltr li button {
  background-color: #47454594;
  border-radius: 0;
  margin: 0px 15px;
  top: 35px;
  width: 56%;
}
.home--Pc {
  display: flex;
  margin: 0 auto;
  width: 1140px;
  justify-content: center;
}
img.Bg--contact--Pc {
  width: 100%;
}
.Banner--box {
  width: 500px;
  margin-top: 5%;
}
.contact--Pc {
  width: 500px;
  margin-top: 9%;
  position: relative;
}
.contact--box {
  position: absolute;
  width: 500px;
  top: 60px;
}
.Games--all--Pc {
  width: 100%;
  position: relative;
  margin-top: 2%;
}
img.icon--img--games {
  width: 50%;
  padding: 3%;
}
img.Text--Games--All--Pc {
  width: 100%;
}

img.Icon--Casino--Pc {
  width: 40%;
}
img.Icon--Sport--Pc {
  width: 40%;
  margin-top: 1%;
}
img.Icon--Slot--Pc {
  width: 40%;
  margin-top: 1%;
}
img.Icon--Lotto--Pc {
  width: 40%;
  margin-top: 1%;
}
.Games--all--box {
    width: 500px;
    margin-top: 5%;
    margin-left: 5%;
}
.Icon--Games--All--Pc {
  background: url("/src/Images/ImgPc/PcImg31.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: absolute;
  width: 260px;
  height: 395px;
  right: 0%;
  top: 0%;
  margin-top: 2%;
}
img.Bg--Games--Pc {

  width: 16%;
  position: fixed;
  right: 20%;
  margin-top: 14%;
}
.Games--center--pc {
  width: 90%;
  height: 325px;
  overflow-y: scroll;
  margin-top: 22%;
  margin-left: 6%;
}
.Games--bar--Pc {
    width: 90%;
}
img.QRCode--Pc {
  width: 30%;
  margin-left: 5%;
}
img.BgLine--Pc {
  width: 50%;
  margin-left: 6%;
  margin-top: 50px;

}
img.BgPhone--Pc {
  width: 12%;
  margin-top: 36%;
  position: fixed;
  margin-left: 36%;
}
h1.Line--Pc {
  position: fixed;
  margin-top: 33%;
  color: #ffff;
  margin-left: 37%;
  font-size: 96%;
  width: 10%;
  padding-top: 11px;
  padding-left: 15px;
}
h1.Phone--Pc {
  position: fixed;
  margin-top: 36%;
  color: black;
  margin-left: 39%;
  font-size: 96%;
  width: 10%;
  padding-top: 9px;
  padding-left: 8px;
}
img.IconLine--Pc {
  width: 2%;
  position: fixed;
  margin-top: 32%;
  margin-left: 35%;
  padding-top: 17px;
}

@media screen and (max-width: 1139px) {
  .home--Pc {
    width: 840px;
}
.Banner--box {
  width: 400px;
}
.Games--all--box {
  width: 400px;
}
.contact--Pc {
  width: 400px;
}
.contact--box {
  width: 400px;
}
.Icon--Games--All--Pc {
  width: 210px;
  height: 319px;
}
.Games--center--pc {
  height: 240px;
}
}

@media screen and (max-width: 940px) {
  .home--Pc {
    width: 620px;
}
.Banner--box {
  width: 250px;
}
.Games--all--box {
  width: 250px;
}
.contact--Pc {
  width: 250px;
}
.contact--box {
  width: 250px;
  top: 30px;
}
.Icon--Games--All--Pc {
  width: 130px;
  height: 197px;
}
.Games--center--pc {
  height: 155px;
}
ul.splide__pagination.splide__pagination--ltr li button {
  background-color: #47454594;
  border-radius: 0;
  margin: 0px 9px;
  top: 26px;
  width: 66%;
}
.Games--bar--Pc {
  width: 85%;
}
}
@media screen and (max-width: 621px) {
  .home--Pc {
    width: 481px;
}
.Banner--box {
  width: 150px;
}
.Games--all--box {
  width: 150px;
}
.contact--Pc {
  width: 150px;
}
.contact--box {
  width: 150px;
  top: 20px;
}
.Icon--Games--All--Pc {
  width: 75px;
  height: 114px;
}
.Games--center--pc {
  height: 85px;
}
ul.splide__pagination.splide__pagination--ltr li button {
  background-color: #47454594;
  border-radius: 0;
  margin: 0px 9px;
  top: 26px;
  width: 66%;
}
.Games--bar--Pc {
  width: 79%;
}
img.BgLine--Pc {
  margin-top: 25px;
}
ul.splide__pagination.splide__pagination--ltr li button {
  margin: 0px 8px;
  top: 22px;
  width: 60%;
}
}
@media screen and (max-width: 480px) {
  .home--Pc {
    display: none;
  }
  .home--Mb {
    display: block;
  }
  .Login--Regis--Mb {
    width: 100%;
    margin-top: 20%;
  }
  img.Register--Mb {
    width: 40%;
    margin-left: 7%;
  }
  img.Login--Mb {
    width: 40%;
    margin-left: 3%;
  }
  .Banner--Mb {
    width: 86%;
    margin-left: 9%;
    margin-top: 5%;
  }
  img.Text--Games--All--Mb {
    width: 90%;
    margin-left: 5%;
    margin-top: 10%;
  }
  img.Icon--Casino--Mb {
    width: 35%;
  }
  img.Icon--Sport--Mb {
    width: 35%;
    margin-top: 2%;
  }
  img.Icon--Slot--Mb {
    width: 35%;
    margin-top: 2%;
  }
  img.Icon--Lotto--Mb {
    width: 35%;
    margin-top: 2%;
  }
  img.Bg--Games--Mb {
    position: absolute;
    width: 46%;
    right: 0%;
    margin-right: 9%;
    margin-top: -62%;
  }
  .Games--all--Mb {
    width: 100%;
    margin-top: 5%;
    margin-left: 4%;
  }
  .home--Mb {
    width: 100%;
    margin-top: 14%;
    position: absolute;
    top: 0%;
    padding-bottom: 50%;
  }
  .Games--bar--Mb {
    position: absolute;
    width: 43%;
    margin-top: -51%;
    right: 0%;
    margin-right: 10%;
  }
  img.HGames--Pro--Mb1 {
    width: 45%;
  }
  img.HGames--Pro--Mb2 {
    width: 45%;
    margin-left: 8%;
  }
  img.HGames--Pro--Mb3 {
    width: 45%;
    margin-top: 5%;
  }
  img.HGames--Pro--Mb4 {
    width: 45%;
    margin-left: 8%;
    margin-top: 5%;
  }
  img.Text--GamesAll--Mb {
    width: 25%;
    margin-top: 10%;
  }
  img.Line--Mb {
    width: 90%;
    margin-left: 5%;
    margin-top: 5%;
  }
}
@media screen and (max-width: 400px) {
  .text--tab--Mb {
    font-size: 10px;
  }
}
@media screen and (max-width: 300px) {
  .text--tab--Mb {
    font-size: 8px;
  }
}
@media screen and (max-width: 250px) {
  .text--tab--Mb {
    font-size: 5px;
  }
}